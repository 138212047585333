<template>
  <div class="bulletBox_wrapper">
    <div class="bulletBox">
      <div class="play_all_video_wrapper">
        <div class="close_video_wrapper">
          <i class="el-icon-circle-close closeIcon" @click="closeBox"></i>
        </div>
        <div class="play_all_wideo">
          <video
            :src="videoUrl"
            controlslist="nodownload noremoteplayback"
            controls
            style="overflow: hidden; width: 100%"
            :autoplay="true"
            muted
            ref="video"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImgUrl } from "@/api/getImgUrl";
export default {
  data() {
    return {
      observer: null,
      videoUrl:""
    };
  },
  mounted() {
    // 初始化Intersection Observer
    this.observer = new IntersectionObserver(this.handleIntersection, {
      threshold: 0.5, // 当视频至少50%进入视窗时触发加载
    });
    this.getvideoUrl()
    // 监听video元素
    this.observer.observe(this.$refs.video);
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 进入视窗，开始加载视频
          this.$refs.video.src = this.videoUrl;
          this.observer.unobserve(this.$refs.video); // 取消监听
        }
      });
    },
    getvideoUrl() {
      getImgUrl("/home.mp4").then((res) => {
        if (res.data.code == 200) {
          this.videoUrl = res.data.data;
          this.$nextTick(() => {
            this.$refs.video.load(); // 强制重新加载视频
          });
        }
      });
    },
    closeBox() {
      // 子组件向父组件传递消息，通过$emit方法
      this.$emit("closeBox", false);
    },
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect(); // 组件销毁时断开Observer连接
    }
  },
};
</script>

<style scoped>
.bulletBox_wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999999999;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bulletBox {
  width: 60%;
  height: 60%;
}

.play_all_video_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.play_all_wideo {
  width: 100%;
  height: 100%;
}

.closeIcon {
  color: #fff;
  font-size: 3rem;
  float: right;
  margin-right: -70px;
  margin-top: -50px;
}
</style>
