<template>
  <div class="cooPartner_wrapper" ref="imageContainer">
    <div class="cooPartner_container">
      <div class="cooPartner_title_wrapper">
        <div class="cooPartner_titleZH">合作伙伴</div>
        <div class="cooPartner_titleEN">COOPERATIVE PARTNER</div>
      </div>
      <div class="cooPartner_list_wrapper">
        <div class="cooPartner_list">
          <div class="cooPartnerImg_wrapper">
            <img :src="src[0]" class="cooPartnerImg" style="width: 70%" />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img :src="src[1]" class="cooPartnerImg" style="width: 70%" />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img :src="src[2]" class="cooPartnerImg" style="width: 45%" />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img :src="src[3]" class="cooPartnerImg" style="width: 125%" />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img :src="src[4]" class="cooPartnerImg" style="width: 80%" />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img :src="src[5]" class="cooPartnerImg" style="width: 75%" />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img :src="src[6]" class="cooPartnerImg" style="width: 45%" />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img :src="src[7]" class="cooPartnerImg" style="width: 65%" />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img :src="src[8]" class="cooPartnerImg" style="width: 70%" />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img :src="src[9]" class="cooPartnerImg" style="width: 80%" />
          </div>
        </div>
      </div>
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import { getImgUrl } from "@/api/getImgUrl";
import bottom from "./Bottom.vue";
export default {
  components: {
    bottom,
  },
  data() {
    return {
      observer: null,
      number: 0,
      src: [],
      imgBaseUrl: [
        "nvidia.png",
        "/aliyun.png",
        "/huawei.jpg",
        "/yuewen.png",
        "/dainziyun.png",
        "/xinlang.png",
        "/xueyanshe.png",
        "/zhongke.png",
        "/qudong.png",
        "/ruijiake.png",
      ],
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.onIntersection, {
      root: null,
      threshold: 0.1,
    });
    this.observer.observe(this.$refs.imageContainer);
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    onIntersection(entries) {
      if (entries[0].isIntersecting) {
        this.getBackImg();
        this.getImgUrlHandle();
        this.observer.disconnect(); // 加载完后取消监听
      }
    },
    async getBackImg() {
      await getImgUrl("/cooPartnerHomeBackground.png").then((res) => {
        if (res.data.code == 200) {
          this.$refs.imageContainer.style.backgroundImage = `url(${res.data.data})`;
        }
      });
    },
    getImgUrlHandle() {
      for (let i = 0; i < this.imgBaseUrl.length; i++) {
        getImgUrl(this.imgBaseUrl[i]).then((res) => {
          if (res.data.code == 200) {
            this.$set(this.src, i, res.data.data); // 动态设置响应式图片URL
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.cooPartner_wrapper {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex; /* 视情况是否需要布局 */
  flex-direction: column;
}
.cooPartner_container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.cooPartner_title_wrapper {
  padding-top: 6%;
  margin-left: 9%;
}
.cooPartner_titleZH {
  font-size: 2.5rem;
  color: #fff;
  font-style: italic;
}
.cooPartner_titleEN {
  font-style: italic;
  font-weight: 600;
  color: #000000;
  font-size: 1.2rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.cooPartner_list_wrapper {
  width: 82%;
  margin-left: 9%;
  margin-top: 20px;
  height: 50%;
  padding-bottom: 40px;
  margin-bottom: 2%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
}
.cooPartner_list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.cooPartnerImg_wrapper {
  flex: 0 0 calc(20% - 10px);
  margin: 5px;
  padding: 10px;
  height: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
}
.cooPartnerImg {
  width: 80%;
}
</style>
