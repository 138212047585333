<template>
  <div>
    <div class="main-contain" ref="back">
      <div class="company_intrduce_title">
        <div class="company_intrduce_titleZH">公司荣誉</div>
        <div class="company_intrduce_titleEN">COMPANY HONORS</div>
      </div>
      <div class="honors_details_wrapper" ref="honor">
        <div class="honor_left_wrapper">
          <div class="xiaozi">
            从<span class="dazi" style="margin: 0 15px">2019年</span>成立至今
          </div>
        </div>
        <div style="display: flex; flex-direction: column; width: 60vw">
          <div class="hono_midel_left_wrapper">
            <div class="hono_midel_wrapper">
              <div
                style="
                  width: 10px;
                  height: 15px;
                  background-color: white;
                  margin-top: 22px;
                "
              ></div>
              <div
                class="dazi"
                style="
                  height: 30px;
                  border-bottom: 2px solid #828080;
                  padding-left: 15px;
                  padding-bottom: 5px;
                "
              >
                派动科技<span class="xiaozi">目前已取得</span>
              </div>
            </div>
            <div style="margin-top: 40px">
              <div class="dazi right_oneLine_wrapper">
                3<span class="xiaozi1">项</span>发明专利
              </div>
              <div class="dazi right_twoLine_wrapper">
                20<span class="xiaozi1">项</span>软件著作权
              </div>
              <div class="dazi right_threeLine_wrapper">
                5<span class="xiaozi1">项</span>作品著作权
              </div>
            </div>
          </div>
          <div class="honor_right_wrapper">
            <div class="right_foreLine_wrapper">
              <div
                style="
                  width: 10px;
                  height: 15px;
                  background-color: white;
                  margin-top: 22px;
                "
              ></div>
              <div
                class="dazi"
                style="
                  height: 30px;
                  border-bottom: 2px solid #828080;
                  padding-left: 15px;
                  padding-bottom: 5px;
                "
              >
                团队成员<span class="xiaozi">目前已取得</span>
              </div>
            </div>
            <div>
              <div style="">
                <span class="dazi">5</span><span class="xiaozi1">项</span
                ><span class="dazi">发明专利</span>
              </div>
              <div class="dazi right_fiveLine_wrapper">
                6 <span class="xiaozi1">篇</span>论文
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImgUrl } from "@/api/getImgUrl";
export default {
  data() {
    return {
      isVisible: false, // Flag to track visibility
    };
  },
  mounted() {
    window.addEventListener("scroll", this.checkVisibility);
    this.checkVisibility(); // Check visibility when the component is mounted
    this.getBackImg();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkVisibility);
  },
  methods: {
    checkVisibility() {
      const element = this.$refs.honor; // Get the component's DOM element
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      if (rect.top >= 0 && rect.bottom <= windowHeight) {
        this.isVisible = true;
        // 添加一个类名来触发动画
        element.classList.add("fade-in-animation");
        console.log("Component is visible in viewport");
        // Do something when the component becomes visible
      } else {
        this.isVisible = false;
        console.log("Component is not visible in viewport");
        // Do something when the component is not visible
      }
    },
    async getBackImg() {
      await getImgUrl("/honor.png").then((res) => {
        if (res.data.code == 200) {
          this.$refs.back.style.backgroundImage = `url(${res.data.data})`;
        }
      });
    },
  },
};
</script>

<style scoped>
/* 添加动画类的样式 */
.fade-in-animation {
  animation: fadeInAnimation ease 3s;
}

.main-contain {
  font-family: "Microsoft YaHei", serif;
  height: 65vh; 
  width: 100%;
  padding-top: 50px;
  background-size: 100% 100%;
  padding-bottom: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex; /* 视情况是否需要布局 */
  flex-direction: column;
}

.company_intrduce_title {
  padding-bottom: 5px;
  width: 10%;
  margin-left: 10%;
  margin-bottom: 30px;
  border-bottom: 1px solid #4e5053;
  margin-top: 50px;
}

.company_intrduce_titleZH {
  font-size: 2rem;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #ffffff;
  font-style: italic;
}

.company_intrduce_titleEN {
  font-size: 1rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.honors_details_wrapper {
  margin-top: 70px;
  display: flex;
  color: #ffffff;
  font-weight: 600;
  font-style: italic;
  width: 70%;
  margin-left: 25%;
  margin-bottom: 150px;
}

.honor_left_wrapper {
  width: 20%;
}

.hono_midel_left_wrapper {
  width: 100%;
  display: flex;
}

.hono_midel_wrapper {
  width: 30%;
  display: flex;
  margin-top: 40px;
}

.honor_right_wrapper {
  width: 100%;
  display: flex;
  margin-top: 60px;
}

.dazi {
  font-size: 2rem;
}

.xiaozi {
  font-size: 1.5rem;
}

.xiaozi1 {
  font-size: 1.5rem;
  margin-left: 15px;
  margin-right: 10px;
}

.right_oneLine_wrapper,
.right_twoLine_wrapper,
.right_threeLine_wrapper,
.right_foreLine_wrapper,
.right_fiveLine_wrapper {
  margin-bottom: 5%;
}
.right_fiveLine_wrapper {
  margin-top: 5%;
}

.right_oneLine_wrapper {
  margin-left: 15px;
}

.right_twoLine_wrapper {
  /* margin-left: -30px; */
}

.right_threeLine_wrapper {
  /* margin-left: -20px; */
}

.right_foreLine_wrapper {
  display: flex;
  width: 30%;
}

.right_fiveLine_wrapper {
  /* margin-left: -5%; */
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    /*设置不透明度*/
  }

  100% {
    opacity: 1;
  }
}
</style>
