import Vue from 'vue'
import Router from 'vue-router'
import home from '../views/Home.vue'
import about from '../views/AboutUs'
import solution from '../views/Solution'
import technology from '../views/Technology'
import userInstance from '../views/UserInstance'

Vue.use(Router)

export default new Router({
    mode: 'history', // 使用 history 模式
    routes: [
        {
            path: "/",
            redirect: "/home",
        },
        {
            path: '/home',
            name: 'home',
            component: home,
        },
        {
            path: '/about',
            name: 'AboutUs',
            component: about,
        },
        {
            path: '/solution',
            name: 'solution',
            component: solution,
        },
        {
            path: '/technology',
            name: 'technology',
            component: technology,
        },
        {
            path: '/userInstance',
            name: 'userInstance',
            component: userInstance,
        },
    ]
})
