<template>
  <div class="solution_cover_wrapper" ref="imageContainer">
    <div class="solution_home_wrapper">
      <div class="solution_home_title_wrapper">
        <div class="solutionZN">解决方案</div>
        <div class="solutionEN">SOLUTION</div>
      </div>
      <div class="solution_home_summary_wrapper">
        <div class="solution_home_category_wrapper">
          <div
            :class="
              number == 0 ? 'solution_home_category_active' : 'solution_home_category'
            "
            @mouseenter="change(0)"
          >
            智享文旅
          </div>
          <div
            :class="
              number == 1 ? 'solution_home_category_active' : 'solution_home_category'
            "
            @mouseenter="change(1)"
          >
            政企办公
          </div>
          <div
            :class="
              number == 2 ? 'solution_home_category_active' : 'solution_home_category'
            "
            @mouseenter="change(2)"
          >
            智慧工业
          </div>
          <div
            :class="
              number == 3 ? 'solution_home_category_active' : 'solution_home_category'
            "
            @mouseenter="change(3)"
          >
            智慧城市
          </div>
          <div
            :class="
              number == 4 ? 'solution_home_category_active' : 'solution_home_category'
            "
            @mouseenter="change(4)"
          >
            智绘影像
          </div>
          <div
            :class="
              number == 5 ? 'solution_home_category_active' : 'solution_home_category'
            "
            @mouseenter="change(5)"
          >
            自然生态
          </div>
          <div
            :class="
              number == 6 ? 'solution_home_category_active' : 'solution_home_category'
            "
            @mouseenter="change(6)"
          >
            智析医疗
          </div>
        </div>
        <div class="solution_home_category_subdivsion_wrapper">
          <div class="solution_home_category_subdivsion" v-if="number == 0">
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">风景识别</div>
              <div class="solution_home_category_subdivsion_titleEN">
                Scenic Recognition
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">运动者图像检索</div>
              <div class="solution_home_category_subdivsion_titleEN">
                Athletes Image Retrieval
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">
                多语言翻译与跨文化交流
              </div>
              <div class="solution_home_category_subdivsion_titleEN">
                Multilingual Translation And Cross-Cultural Communication
              </div>
            </div>
          </div>
          <div class="solution_home_category_subdivsion" v-if="number == 1">
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">
                文件书写大语言模型定制
              </div>
              <div class="solution_home_category_subdivsion_titleEN">
                Customization Of Large Language Models For Document Writing
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">
                智能问答系统和智能客服
              </div>
              <div class="solution_home_category_subdivsion_titleEN">
                Intelligent Question-Answering System And Intelligent Customer Service
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">
                舆情报告生成系统
              </div>
              <div class="solution_home_category_subdivsion_titleEN">
                Public Opinion Report Generation System
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">金融研报编写</div>
              <div class="solution_home_category_subdivsion_titleEN">
                Financial Research Report Writing
              </div>
            </div>
          </div>
          <div class="solution_home_category_subdivsion" v-if="number == 2">
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">
                工厂危险行为检测
              </div>
              <div class="solution_home_category_subdivsion_titleEN">
                Factory Hazardous Behavior Detection
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">工业缺陷检测</div>
              <div class="solution_home_category_subdivsion_titleEN">
                Industrial Defect Detection
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">墙体缺陷检测</div>
              <div class="solution_home_category_subdivsion_titleEN">
                Wall Defect Detection
              </div>
            </div>
          </div>
          <div class="solution_home_category_subdivsion" v-if="number == 3">
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">路况实时监测</div>
              <div class="solution_home_category_subdivsion_titleEN">
                Real-Time Traffic Monitoring
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">动作行为检测</div>
              <div class="solution_home_category_subdivsion_titleEN">
                Motion Behavior Detection
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">
                语音识别和语音交互
              </div>
              <div class="solution_home_category_subdivsion_titleEN">
                Speech Recognition And Voice Interaction
              </div>
            </div>
          </div>
          <div class="solution_home_category_subdivsion" v-if="number == 4">
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">AI绘画</div>
              <div class="solution_home_category_subdivsion_titleEN">AI Painting</div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">影像渲染与修复</div>
              <div class="solution_home_category_subdivsion_titleEN">
                Image Rendering And Restoration
              </div>
            </div>
          </div>
          <div class="solution_home_category_subdivsion" v-if="number == 5">
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">
                野生保护动物辨别与统计
              </div>
              <div class="solution_home_category_subdivsion_titleEN">
                Identification And Statistics Of Wildlife Conservation Animals
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">环境监测和预警</div>
              <div class="solution_home_category_subdivsion_titleEN">
                Environmental Monitoring And Early Warning
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">自然资源管理</div>
              <div class="solution_home_category_subdivsion_titleEN">
                Natural Resource Management
              </div>
            </div>
          </div>
          <div class="solution_home_category_subdivsion" v-if="number == 6">
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">
                医学影像智能筛查诊断
              </div>
              <div class="solution_home_category_subdivsion_titleEN">
                Intelligent Screening And Diagnosis Of Medical Images
              </div>
            </div>
            <div class="solution_home_category_subdivsion_title_wrapper">
              <div class="solution_home_category_subdivsion_titleZH">
                医疗数据管理和分析
              </div>
              <div class="solution_home_category_subdivsion_titleEN">
                Management And Analysis Of Medical Data
              </div>
            </div>
          </div>
          <div class="solution_home_category_learnMore_wrapper" @click="learnMore">
            <div class="solution_home_category_learnMore">
              了解更多<i class="el-icon-arrow-right" style="margin-left: 10px"></i>
            </div>
          </div>
        </div>
        <div class="solution_home_category_img_wrapper">
          <img :src="src[number]" style="width: 100%; height: 100%" v-if="src[number]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImgUrl } from "@/api/getImgUrl";
export default {
  data() {
    return {
      observer: null,
      number: 0,
      src: [],
      imgBaseUrl: [
        "/solution/culturalTravel/travel.png",
        "/solution/work/work.png",
        "/solution/industry/industry.png",
        "/solution/city/city.png",
        "/solution/image/image.png",
        "/solution/ecology/ecology.png",
        "/solution/medicalTreatment/medicalTreatment.png",
      ],
    };
  },
  props: ["showChat"],
  watch: {},
  created() {},
  mounted() {
    this.observer = new IntersectionObserver(this.onIntersection, {
      root: null,
      threshold: 0.1,
    });
    this.observer.observe(this.$refs.imageContainer);
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    change(index) {
      console.log(index);
      this.number = index;
    },
    // 点击了解更多跳转页面到解决方案
    learnMore() {
      this.$router.push({
        path: "/solution",
        query: {
          number: this.number,
        },
      });
    },
    onIntersection(entries) {
      if (entries[0].isIntersecting) {
        this.getBackImg();
        this.getImgUrlHandle();
        this.observer.disconnect(); // 加载完后取消监听
      }
    },
    async getImgUrlHandle() {
      for (let i = 0; i < this.imgBaseUrl.length; i++) {
        await getImgUrl(this.imgBaseUrl[i]).then((res) => {
          if (res.data.code == 200) {
            this.$set(this.src, i, res.data.data); // 动态设置响应式图片URL
          }
        });
      }
    },
    async getBackImg() {
      await getImgUrl("/solutionHomeBackground.jpg").then((res) => {
        if (res.data.code == 200) {
          const solutionCover = document.querySelector(".solution_cover_wrapper");
          solutionCover.style.width = "100%";
          solutionCover.style.height = "100vh";
          solutionCover.style.background = res.data.data + " no-repeat";
          solutionCover.style.backgroundSize = "cover";
        }
      });
    },
  },
};
</script>

<style scoped>
/*.solution_cover_wrapper {
  width: 100%;
  height: 100vh;
  background: url("../assets/solutionHomeBackground.jpg") no-repeat;
  background-size: cover;
}*/

.solution_home_wrapper {
  width: 76%;
  height: 62.5%;
  padding-top: 15%;
  margin-left: 9%;
}

.solution_home_title_wrapper {
  margin-left: 50px;
}

.solutionZN {
  font-size: 2.5rem;
  color: #fff;
  font-style: italic;
}

.solutionEN {
  font-size: 1.2rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.solution_home_summary_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 30px;
}

.solution_home_category {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.71rem;
  margin-top: 20px;
  cursor: pointer;
}

.solution_home_category_wrapper {
  margin-left: 60px;
  width: 10%;
  margin-right: 5%;
}

.solution_home_category_active {
  margin-top: 20px;
  cursor: pointer;
  color: #fff;
  font-size: 1.71rem;
}

.solution_home_category_subdivsion_wrapper {
  width: 30%;
  margin-left: 2.5%;
  margin-right: 10%;
}

.solution_home_category_subdivsion {
  width: 100%;
  margin-top: 30px;
}

.solution_home_category_subdivsion_title_wrapper {
  margin-top: 30px;
  color: #fff;
}

.solution_home_category_subdivsion_titleZH {
  font-size: 1.07rem;
  font-weight: 600;
}

.solution_home_category_subdivsion_titleEN {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
}

.solution_home_category_img_wrapper {
  width: 50%;
  height: 71.4%;
}

.solution_home_category_learnMore_wrapper {
  color: #fff;
  font-size: 0.78rem;
  margin-top: 40px;
  cursor: pointer;
}
</style>
