<template>
  <div class="scroll_wrapper">
    <navigatorBar
      style="background: rgba(0, 0, 0, 0.5); z-index: 999999999"
    ></navigatorBar>
    <!-- <i class="el-icon-chat-dot-round" style="position: fixed;z-index: 999999999;color: white;font-size: 40px;top: 90%;left: 95%;"></i> -->
    <div class="solution_main_wrapper">
      <div class="solutionBackground_wrapper">
        <img :src="imgBackUrl" class="solutionBackgroundImg" />
      </div>
      <div class="backIcon_wrapper">
        <i class="el-icon-back backIcon" @click="goBack"></i>
      </div>
      <div class="techTitle_wrapper" v-if="index == 0">
        <div class="techZHTitle">墙体缺陷检测</div>
        <div class="techENTitle">WALL DEFECT DETECTION</div>
      </div>
      <div class="techTitle_wrapper" v-if="index == 1">
        <div class="techZHTitle">游戏舆情分析及报告</div>
        <div class="techENTitle">GAME PUBLIC OPINION ANALYSIS AND REPORT</div>
      </div>
      <div class="techTitle_wrapper" v-if="index == 2">
        <div class="techZHTitle">工厂安全行为检测</div>
        <div class="techENTitle">INDUSTRIAL SAFETY BEHAVIOR DETECTION</div>
      </div>
      <div class="techTitle_wrapper" v-if="index == 3">
        <div class="techZHTitle">滑雪者图像检索</div>
        <div class="techENTitle">SKIER IMAGE RETRIEVAL</div>
      </div>
      <div class="techTitle_wrapper" v-if="index == 4">
        <div class="techZHTitle">政企舆情分析</div>
        <div class="techENTitle">GOVERNMENT AND ENTERPRISE PUBLIC OPINION ANALYSIS</div>
      </div>
      <div class="all_wrapper">
        <div class="project_background_wrappe">
          <div class="project_background">
            <div class="project_background_titleZH">项目背景</div>
            <div class="project_background_titleEN">PROJECT BACKGROUND</div>
          </div>
        </div>
        <div class="progect_background_content_des_wrapper">
          <div class="progect_background_content_des" v-if="index == 0">
            <div class="progect_background_content_des_text">
              随着人们对居住环境的要求越来越高，房屋的质量与安全问题备受关注。然而在生活中墙体缺陷问题时有发生，这不仅影响了建筑物的外观和使用寿命，更涉及到业主的生命财产安全。墙体缺陷检测与修复就成了一项必不可少的重要工作。然而传统的墙体缺陷检测方式存在一系列问题：
              <br />
              1、检测主要依赖于人工肉眼观察，效率低、准确性不高，易受到人为主观因素的影响、人工成本高。
              <br />
              2、一些缺陷难以被肉眼识别，需要借助专业技术和设备。
              <br />
              3、高空作业的安全隐患和劳动强度也限制了人工检测的应用。
              <br />
              因此，需要一种高效、准确、自动化的方法来识别和定位墙体缺陷，适用于不同类型和规模的检测任务，以减轻人力劳动强度，降低作业风险。
            </div>
            <img :src="projectImgList[0]" style="width: 50%; margin-left: 25%" />
          </div>
          <div class="progect_background_content_des" v-if="index == 1">
            <div class="progect_background_content_des_text">
              在现代娱乐产业中，游戏产业的快速发展使得游戏舆情分析变得尤为重要。游戏不仅仅是娱乐方式，更是一个社交和文化现象，玩家之间的互动和对游戏的评价在网络上广泛传播。游戏企业需要深入了解玩家的情感、反馈和讨论，以便更好地满足他们的需求，改进游戏并保持品牌形象。此外，随着社交媒体和在线平台的普及，网络上的游戏舆情对游戏企业的声誉和市场表现产生了巨大影响，因此准确的舆情分析成为成功的关键因素。
            </div>
            <img :src="projectImgList[1]" style="width: 50%; margin-left: 25%" />
          </div>
          <div class="progect_background_content_des" v-if="index == 2">
            <div class="progect_background_content_des_text">
              在众多工厂作业领域，安全隐患问题一直是一大挑战。工厂作业的复杂性和多样性使得作业环境的安全隐患愈发突出。许多工厂存在着作业环境混乱、设施设备存在潜在危险等问题。采光照明不良和设备操作盲区等因素更是容易引发严重的危险事故。尽管人工排除安全隐患，但由于存在盲区，往往难以做到全面，容易出现错漏。当前的安全管理方式存在一些局限性，一旦危险事故发生，其补救性较低，往往已经造成了无法挽回的损失。
              <br />
              因此，为了解决这些问题，迫切需要一种创新性的方法来提高工厂作业的安全性。
            </div>
            <img :src="projectImgList[2]" style="width: 50%; margin-left: 25%" />
          </div>
          <div class="progect_background_content_des" v-if="index == 3">
            <div class="progect_background_content_des_text">
              滑雪是一项受到广大人民群众喜爱的运动，也是一种展现个性和风采的方式。很多滑雪爱好者都喜欢在滑雪场拍摄自己或他人的滑雪视频，记录下美好的时光。而雪研社也配备了专业摄影师对雪场中的人进行拍摄，滑雪结束后大家可以在小程序上自行下载。然而，由于滑雪人数众多，滑雪场的环境复杂，以及滑雪服的颜色相似等因素，怎么在大量视频中快速找到自己的所有素材变成一个难题。
            </div>
            <img :src="projectImgList[3]" style="width: 50%; margin-left: 25%" />
          </div>
          <div class="progect_background_content_des" v-if="index == 4">
            <div class="progect_background_content_des_text">
              在互联网高度普及的当今社会，政府、企业以及各种组织面临很大的舆情压力，舆情问题的迅速发展和传播可能会对这些主体的形象和声誉造成巨大损害，产生深远的负面影响。舆情危机的频发导致了对即时预警、深入分析和迅速处理的需求不断增加，这对政府部门和企业而言变得越来越重要。
            </div>
            <img :src="projectImgList[4]" style="width: 50%; margin-left: 25%" />
          </div>
        </div>
        <div class="project_background_wrappe">
          <div class="project_background">
            <div class="project_background_titleZH">项目内容</div>
            <div class="project_background_titleEN">PROJECT CONTENT</div>
          </div>
        </div>
        <div class="project_conten_des_wrapper">
          <div class="project_conten_des" v-if="index == 0">
            基于上述背景，我们开展了墙体缺陷检测的项目，项目内容包括：
            <br />
            1、开发算法：开发智能墙体缺陷检测算法，采用人工智能领域中的目标检测和语义分割技术实现智能墙体缺陷检测功能。
            <br />
            2、采集数据：用无人机采集墙体照片数据，实现了高空视角、操作灵活、安全性高等要求，更有效替代人工高空检测。
            <br />
            3、红外成像：采集正常可见光的图像，结合红外成像技术，即可检测目标表面微小的温度变化、结合AI算法就能发现潜在缺陷。
            <br />
            4、缺陷检测：可以批量通过算法智能检测出墙体缺陷，如空鼓、渗漏、裂缝等，还可以分割出缺陷的具体轮廓以准确计算缺陷参数，如空鼓面积、渗漏面积、裂缝长度等，提供了更详细的评估信息。
          </div>
          <div class="project_conten_des" v-if="index == 1">
            基于上述背景，我们开展了基于机器学习的游戏舆情分析项目。
            <br />
            本项目将运用机器学习、文本分类和情感分析等自然语言处理技术，针对游戏领域的热点话题和突发事件，进行智能识别和定向追踪。
            <br />
            通过分析玩家社群中的讨论数据，我们将建立文本分类模型，实现对舆论的分析和情感分类。这将帮助游戏企业及时了解网络传播动向，为应对网络舆情事件、掌握市场需求动态以及做出游戏改进提供决策依据。
          </div>
          <div class="project_conten_des" v-if="index == 2">
            基于上述背景，我们为一家国企的工厂制作了工厂安全作业行为检测系统，以协助他们减少在工厂作业中存在的安全隐患问题。项目内容包括：
            <br />
            1、AI算法定制与开发：
            我们运用cv技术，开发了适应工厂安全需求的AI算法，能够通过监测摄像头画面，识别危险行为。
            <br />
            2、前后端开发：针对视频传输流的问题进行优化，定制了与AI算法适配良好的前后端，构建了完整的操作系统。
            <br />
            3、实时监测：
            设置了多个高分辨率摄像头，同时监控关键区域。系统通过AI算法实时分析画面，检测违规行为。
            <br />
            4、声光报警：该系统最主要的目的是为了保障工人的人身安全。经过算法实时监测后，一旦发现违规的危险操作，将会在厂内发出声光报警，提醒工人注意安全。
            <br />
            5、违规行为记录与回看：
            系统记录所有违规行为，提供回看功能，便于后续分析改进和惩处。
            <br />
            6、部署与数据安全： 将系统部署在工厂本地服务器上，保证了敏感数据的隐私与安全。
          </div>
          <div class="project_conten_des" v-if="index == 3">
            基于上述背景，雪研社与派动科技进行合作，我们运用人工智能技术，提供了一种基于深度学习的滑雪者图像检索方法和系统，为雪研社拍摄的滑雪视频进行检测识别，让滑雪者能快速找到自己的视频素材，更能通过“相似视频”检测出近一个月内的所有相关视频。
            <img
              :src="contenimgUrlList[0]"
              style="width: 40%; margin-top: 30px; margin-left: 30%"
            />
          </div>
          <div class="project_conten_des" v-if="index == 4">
            基于上述背景，我们开展了政企舆情分析的项目，本项目利用机器学习、文本分类、情感分析等自然语言处理技术，针对社会热点话题和突发事件，实现智能识别和有针对性的追踪。通过这些技术，还可以生成详尽的舆情分析报告，帮助政府、媒体和企事业单位及时了解网络舆情的发展趋势。这将为他们应对网络舆情事件、洞察市场需求动态以及进行改进提供决策支持。
          </div>
        </div>
        <div class="project_background_wrappe">
          <div class="project_background">
            <div class="project_background_titleZH">项目成果</div>
            <div class="project_background_titleEN">PROJECT RESULTS</div>
          </div>
        </div>
        <div class="project_result_wrapper">
          <div class="project_result_des_wrapper" v-if="index == 0">
            <div class="project_result_des_text">
              自研的PY-HSNet深度学习算法，能够实时检测待检测目标是否存在缺陷、缺陷类别、缺陷位置等信息。经过测试，该算法检测精度达到87.21%，速度达35.6FPS/S，
              <br />
              随着技术的不断进步，智能墙体缺陷检测技术有望在更多领域得到应用，除了用于住宅建筑，还可以在公共建筑、工业设施等领域发挥作用，提升建筑物的质量和安全水平。同时，随着数据和算法的积累，系统的准确性和稳定性也将不断提升，为人们创造更加安全、舒适的居住环境。
            </div>
            <div class="project_result_img_wrapper">
              <img :src="resultImgUrlList[0]" width="35%" />
              <img :src="resultImgUrlList[1]" width="35%" />
            </div>
          </div>
          <div class="project_result_des_wrapper" v-if="index == 1">
            <div class="project_result_des_text">
              本项目主要是为国内某款知名IP游戏进行舆情分析。我们每周产出详尽的舆情分析报告，深入剖析玩家的情感、评论和反馈。通过构建游戏文本分类模型，我们能够更好地理解玩家的动向和需求，为游戏企业的决策制定提供实际依据。未来，我们计划将这一模型推广至更多游戏企业，帮助他们更好地了解玩家社群，并为游戏行业的持续发展贡献一份力量。
            </div>
            <div class="project_result_img_wrapper">
              <img
                :src="resultImgUrlList[2]"
                style="width: 50%"
              />
            </div>
          </div>
          <div class="project_result_des_wrapper" v-if="index == 2">
            <div class="project_result_des_text">
              工厂安全行为检测系统通过实时监控工厂作业环境中的安全状态，预警潜在的危险因素，以及提供及时的干预措施，为工厂作业带来新的安全管理模式，有效减少危险事故的发生，提高作业环境的安全性，最终实现生产效率和员工安全的双赢局面。
              <br />
              随着AI技术的不断发展，AI赋能各行各业将成为主流趋势。本项目为“AI+制造业”模式的可行性和价值提供了有力的证据，将在工业安全领域产生深远影响，推动工厂作业更加智能化、安全化、高效化的发展。
            </div>
            <div class="project_result_img_wrapper">
              <img
                :src="resultImgUrlList[3]"
                style="width: 50%"
              />
            </div>
          </div>
          <div class="project_result_des_wrapper" v-if="index == 3">
            <div class="project_result_des_text">
              每天在雪研社拍摄的滑雪视频有数百个，滑雪者图像检索方法和系统可以在几秒钟内完成检索任务，准确率高达90%以上。该方法和系统已经在雪研社拍摄的滑雪图片上进行了测试，取得了良好的效果。与现有技术相比，他能够显著提高滑雪者图像检索的准确率和召回率，并降低漏检、误检的概率。
              <br />
              《一种基于深度学习的滑雪者图像检索方法和系统》，已于2023年3月获得发明专利证书.
            </div>
            <div class="project_result_img_wrapper">
              <img
                :src="resultImgUrlList[4]"
                width="30%"
                style="margin-left: 5%"
              />
              <img
                :src="resultImgUrlList[5]"
                width="31%"
                style="margin-left: -10%"
              />
            </div>
          </div>
          <div class="project_result_des_wrapper" v-if="index == 4">
            <div class="project_result_des_text">
              本项目实现了全媒体大数据全网舆情监测和风险管理，通过行业专家的精选报告推送和定制化舆情分析报告，提供对舆情问题的深入洞察。我们还建立了多渠道实时预警系统，结合人工实时预警，帮助政府和企业及时进行危机管理和应对。这将帮助他们更好地了解舆情态势、制定应对策略，并在关键时刻做出明智的决策。
              <br />
              此外，通过舆情分析，政府和企业还能够及时了解民意、产品社会反响以及市场和社会需求，也有利于进行市场调查和品牌建设。
            </div>
            <div class="project_result_img_wrapper">
              <img
                :src="resultImgUrlList[6]"
                style="width: 50%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom style="z-index: 99"></bottom>
  </div>
</template>

<script>
import { getImgUrl } from "@/api/getImgUrl";
import navigatorBar from "../components/NavigatorBar.vue";
import bottom from "../components/Bottom.vue";
import swiper from "../components/CvSwiper.vue";
export default {
  data() {
    return {
      flag: 0,
      index: 0,
      imgBackUrl: "",
      projectBaseUrlList: [
        "/instance/wall/back.png",
        "/instance/game/back.jpg",
        "/instance/factory/back.jpg",
        "/instance/player/back.jpg",
        "/instance/government/back.jpg",
      ],
      projectImgList: [],
      contenBaseUrlList: ["/instance/player/content.jpg"],
      contenimgUrlList: [],
      resultBaseUrlList:[
        "/instance/wall/result2.jpg",
        "/instance/wall/result1.jpg",
        "/instance/game/result.png",
        "/instance/factory/result.png",
        "/instance/player/result1.png",
        "/instance/player/result2.png",
        "/instance/government/result.png",
      ],
      resultImgUrlList:[]
    };
  },
  components: {
    navigatorBar,
    bottom,
    swiper,
  },
  created() {
    if (this.$route.query.index) {
      this.index = this.$route.query.index;
    }
    this.getBackImg();
    this.getProjectImg();
    this.getContentImg();
    this.getResultImg()
  },
  methods: {
    select(index) {
      this.flag = index;
    },
    goBack() {
      this.$router.go(-1);
    },
    async getBackImg() {
      await getImgUrl("/cooPartnerHomeBackground.png").then((res) => {
        if (res.data.code == 200) {
          this.imgBackUrl = res.data.data;
        }
      });
    },
    getProjectImg() {
      for (let i = 0; i < this.projectBaseUrlList.length; i++) {
        getImgUrl(this.projectBaseUrlList[i]).then((res) => {
          if (res.data.code == 200) {
            this.$set(this.projectImgList, i, res.data.data);
          }
        });
      }
    },
    getContentImg() {
      for (let i = 0; i < this.contenBaseUrlList.length; i++) {
        getImgUrl(this.contenBaseUrlList[i]).then((res) => {
          if (res.data.code == 200) {
            this.$set(this.contenimgUrlList, i, res.data.data);
          }
        });
      }
    },
    getResultImg(){
      for (let i = 0; i < this.resultBaseUrlList.length; i++) {
        getImgUrl(this.resultBaseUrlList[i]).then((res) => {
          if (res.data.code == 200) {
            this.$set(this.resultImgUrlList, i, res.data.data);
          }
        });
      }
    }
  },
};
</script>

<style scoped>
.solution_main_wrapper {
  width: 100vw;
  /* display: flex; */
  /* height: 113vw; */
}

.solutionBackground_wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.solutionBackgroundImg {
  width: 100%;
  height: 100%;
}

.backIcon {
  /* margin-top: 9.554vw; */
  color: #ffffff;
  position: absolute;
  font-size: 2.8rem;
  cursor: pointer;
  margin-left: 150px;
}

.techTitle_wrapper {
  margin-top: 9.554vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.techZHTitle {
  font-size: 2.5rem;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #ffffff;
  font-style: italic;
}

.techENTitle {
  font-size: 1.2rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  margin-top: 0.675vw;
  color: #000000;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.all_wrapper {
  width: 70%;
  margin-left: 15%;
  margin-top: 50px;
  margin-bottom: 150px;
}

.project_background_wrappe {
  width: 100%;
}

.project_background {
  width: 20%;
  padding-bottom: 5px;
  border-bottom: 2px solid #585e68;
}

.project_background_titleZH {
  font-size: 2rem;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #ffffff;
  font-style: italic;
}

.project_background_titleEN {
  font-size: 1rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.progect_background_content_des_wrapper {
  width: 100%;
}

.progect_background_content_des {
  width: 100%;
  margin-top: 50px;
  color: #ffffff;
  margin-bottom: 100px;
}

.progect_background_content_des_text {
  /* text-indent: 2em; */
  margin-bottom: 30px;
  font-size: 1.5rem;
}

.project_conten_des {
  /* text-indent: 2em; */
  margin-bottom: 100px;
  color: #ffffff;
  margin-top: 30px;
  font-size: 1.5rem;
}

.project_result_wrapper {
  width: 100%;
}

.project_result_des_wrapper {
  width: 100%;
}

.project_result_des_text {
  width: 100%;
  color: #ffffff;
  margin-top: 30px;
  font-size: 1.5rem;
}

.project_result_img_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}
</style>
