<template>
  <div>
    <navigatorBar
      style="top: 0vh; background: rgba(0, 0, 0, 0); z-index: 999999999"
    ></navigatorBar>
    <!-- <i class="el-icon-chat-dot-round" style="position: fixed;z-index: 999999999;color: white;font-size: 40px;top: 90%;left: 95%;"></i> -->
    <div class="fullPage" ref="fullPage" style="overflow: hidden">
      <div
        class="fullPageContainer"
        ref="fullPageContainer"
        @mousewheel="mouseWheelHandle"
        @DOMMouseScroll="mouseWheelHandle"
      >
        <div class="section section1">
          <!-- <specialEffects></specialEffects>
          <div class="homeTop_wrapper">
            <div class="homeTopImg_wrapper">
              <img
                src='../assets/homeTop.jpg'
                class="homeTopImg"
              />
            </div>
            <div class="homeTopTitle_wrapper">
              <div class="topTitle">技术应用案例</div>
              <div class="subTitle">
                业务涵盖目标检测、图像分割、动作识别、
                图像生成和AI数据挖掘与分析
              </div>
            </div>
            <div class="homeTop_btn_wrapper" @click="jumpPage(2)">
              <div class="homeTop_btn">探索更多</div>
            </div>
          </div> -->
          <!-- <div class="homeTopTitle_wrapper">
            <div class="topTitle">云南派动科技有限公司</div>
            <h1 class="subTitle">
              派动科技是以人工智能和数据科学为核心的科技创新型企业，公司的理念是“人工智能，创造价值”，公司致力于用领先的数据科学技术和先进的人工智能算法，在工业制造、金融、智慧城市和生命科学等领域创造出真正有价值的智能产品，立志打造成世界顶尖的人工智能企业。目前，我们主要专注于计算机视觉、自然语言处理和知识图谱领域，涵盖了智慧城市、智慧文旅、生命科学、智能制造等行业，核心技术包括物体检测、关键点定位、身份验证、遥感图像解译、文字识别、数据挖掘与分析、智能绘画、医学图像分析、数字人和内容增强等。
            </h1>
          </div> -->
          <div
            class="home_video_wrapper"
            style="height: 100%; width: 100%; overflow: hidden"
          >
            <video
              ref="videoPlayer"
              :controls="false"
              autoplay
              style="overflow: hidden"
              loop
              poster="../assets/first_frame.png"
              muted
            >
              <source :src="videoSrc" type="video/mp4" />
            </video>
            <div class="play_full_video_wrapper">
              <div class="play_full_video">
                <div class="play_video_wrapper" @click="showBox = true">
                  <i
                    class="el-icon-video-play"
                    style="margin-right: 10px; font-size: 2.5rem"
                  ></i>
                  <div style="font-size: 1.5rem">播放完整视频</div>
                </div>
              </div>
            </div>
            <div class="indicator_arrow_wrapper">
              <div class="indicator_arro">
                <i class="el-icon-arrow-down cionXIALA"></i>
                <div style="font-size: 1rem">滚动鼠标翻页</div>
              </div>
            </div>
          </div>
          <bulletBox v-if="showBox" @closeBox="handleCloseBox"></bulletBox>
        </div>
        <div class="section section2">
          <!-- <div class="solution_cover_wrapper">
            <div class="homeSolutionTitle_wrappe">
              <div class="homeSolutionTitleZh">解决方案</div>
              <div class="homeSolutionTitleEn">SOLUTION</div>
            </div>
            <div class="solution_category_wrapper">
              <div :class="{ homeCulturalTourism: 0 === number }" @mouseenter="change(0)">
                智享文旅
              </div>
              <div :class="{ homeCulturalTourism: 1 === number }" @mouseenter="change(1)">
                智析医疗
              </div>
              <div :class="{ homeCulturalTourism: 2 === number }" @mouseenter="change(2)">
                智绘影像
              </div>
              <div :class="{ homeCulturalTourism: 3 === number }" @mouseenter="change(3)">
                智能检测
              </div>
              <div :class="{ homeCulturalTourism: 4 === number }" @mouseenter="change(4)">
                智识生物多样性
              </div>
            </div>
            <div class="solution_category_detial_img">
              <img src="../assets/techCulturalTourism.jpg" class="solution_category_detial_img1" v-show="0 === number" />
              <img src="../assets/techMedicalCare.jpg" class="solution_category_detial_img1" v-show="1 === number" />
              <img src="../assets/techVideo.jpg" class="solution_category_detial_img1" v-show="2 === number" />
              <img src="../assets/techDetection.jpg" class="solution_category_detial_img1" v-show="3 === number" />
              <img src="../assets/thecBioDiversity.jpg" class="solution_category_detial_img1" v-show="4 === number" />
            </div>
            <div class="solution_category_detial">
              <div class="solution_category_detial_all" v-show="0 === number">
                <div class="solution_category_detial_title">
                  <div class="solution_category_detial_ZH">智享文旅</div>
                  <div class="solution_category_detial_EN">
                    AI IN CULTURE AND TOURISM
                  </div>
                </div>
                <div class="solution_category_detial_describe">
                  在文化中驻足，于风景处沉醉。
                  与AI相伴，展现城市底蕴，智享定制旅程。
                </div>
              </div>
              <div class="solution_category_detial_all" v-show="2 === number">
                <div class="solution_category_detial_title">
                  <div class="solution_category_detial_ZH">智绘影像</div>
                  <div class="solution_category_detial_EN">
                    INTELLIGENT DRAWING IMAGE
                  </div>
                </div>
                <div class="solution_category_detial_describe">
                  数字世界，过去的来到现在、现在的走向未来；每一个阶段都要丰富多彩，都要与众不同；用AI的想象力，智绘影像，智绘大千世界。
                </div>
              </div>
              <div class="solution_category_detial_all" v-show="1 === number">
                <div class="solution_category_detial_title">
                  <div class="solution_category_detial_ZH">智析医疗</div>
                  <div class="solution_category_detial_EN">
                    INTELLIGENT ANALYSIS OF MEDICAL TREAMENT
                  </div>
                </div>
                <div class="solution_category_detial_describe">
                  经脉纵横，脏腑有形，基因图谱，分子建模。识医学影像，秋毫必现，解基因数据，锱铢必较。智析医疗，智慧健康。
                </div>
              </div>
              <div class="solution_category_detial_all" v-show="3 === number">
                <div class="solution_category_detial_title">
                  <div class="solution_category_detial_ZH">智能检测</div>
                  <div class="solution_category_detial_EN">
                    INTELLIGENT DETECTION
                  </div>
                </div>
                <div class="solution_category_detial_describe">
                  动，车来车往，川流不息；静，风吹日晒，巍然不动；智能检测，在繁杂中统数据，于细微处辨虚实。
                </div>
              </div>
              <div class="solution_category_detial_all" v-show="4 === number">
                <div class="solution_category_detial_title">
                  <div class="solution_category_detial_ZH">智识生物多样性</div>
                  <div class="solution_category_detial_EN">
                    INTELLECTUAL BIODIVERSITY
                  </div>
                </div>
                <div class="solution_category_detial_describe">
                  同为生命，它是什么，它在哪里，它有什么习性，它与谁生活？通过AI的眼睛，可以让你我站在云端，智识生物多样性。
                </div>
              </div>
              <div class="solution_category_btn_wrapper" @click="jumpPage(number)">
                <div class="solution_category_btn">了解更多</div>
              </div>
            </div>
            <div class="drawLinePoint">
              <div class="point1"></div>
              <div class="line"></div>
              <div class="bigCircle">
                <div class="point2"></div>
              </div>
              <div class="point3"></div>
              <div class="point4"></div>
              <div class="point5"></div>
            </div>
          </div> -->
          <section2></section2>
        </div>
        <div class="section section3">
          <!-- <div class="categories_wrapper">
            <div class="categories_title_wrapper">
              <div class="categories_ZHtitle">各类别应用案例</div>
              <div class="categories_ENtitle">CATEGORIES APPLICATION CASE</div>
            </div>
            <swiper></swiper>
            <div class="caterories_btn_wrapper" @click="jumpPage">
              <div class="caterories_btn">探索更多</div>
            </div>
          </div> -->
          <CooperativePartner></CooperativePartner>
        </div>
        <!-- <div class="section section4">
          <bottom style="height:100%"></bottom>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { getImgUrl } from "@/api/getImgUrl";
import navigatorBar from "../components/NavigatorBar.vue";
import bottom from "../components/Bottom.vue";
import specialEffects from "../components/SpecialEffects.vue";
import section2 from "../components/Setion2.vue";
import bulletBox from "../components/BulletBox.vue";
import CooperativePartner from "@/components/CooperativePartner.vue";
export default {
  name: "Home",
  data() {
    return {
      videoSrc: "",
      number: 0,
      lastTime: 0,
      showBox: false,
      fullpage: {
        current: 1, // 当前的页面编号
        isScrolling: false, // 是否在滚动,是为了防止滚动多页，需要通过一个变量来控制是否滚动
        deltaY: 0, // 返回鼠标滚轮的垂直滚动量，保存的鼠标滚动事件的deleteY,用来判断是往下还是往上滚
      },
    };
  },
  components: {
    navigatorBar,
    bottom,
    specialEffects,
    section2,
    CooperativePartner,
    bulletBox,
  },
  methods: {
    getvideoUrl() {
      getImgUrl("/home_1.mp4").then((res) => {
        if (res.data.code == 200) {
          this.videoSrc = res.data.data;
          this.$nextTick(() => {
            this.$refs.videoPlayer.load(); // 强制重新加载视频
          });
        }
      });
    },
    handleCloseBox(val) {
      console.log(val);
      this.showBox = val;
    },
    // playVideo() {
    //   // this.$refs.videoPlayer.play();
    // },
    resizeVideo() {
      const videoElement = this.$refs.videoPlayer;
      if (videoElement) {
        const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
        const screenWidth = window.innerWidth;
        const newHeight = screenWidth / aspectRatio;

        videoElement.style.width = `${screenWidth}px`;
        videoElement.style.height = `${newHeight}px`;
      }
    },
    change: function (index) {
      this.number = index; //重要处
    },
    jumpPage(n) {
      this.$router.push({
        path: "/solution",
        query: {
          n: n,
        },
      });
    },
    next() {
      // 往下切换
      let len = 3; // 页面的个数
      if (this.fullpage.current + 1 <= len) {
        // 如果当前页面编号+1 小于总个数，则可以执行向下滑动
        this.fullpage.current += 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    pre() {
      // 往上切换
      if (this.fullpage.current - 1 > 0) {
        // 如果当前页面编号-1 大于0，则可以执行向下滑动
        this.fullpage.current -= 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    move(index) {
      this.fullpage.isScrolling = true; // 为了防止滚动多页，需要通过一个变量来控制是否滚动
      this.directToMove(index); //执行滚动
      setTimeout(() => {
        //这里的动画是1s执行完，使用setTimeout延迟1s后解锁
        this.fullpage.isScrolling = false;
      }, 1010);
    },
    directToMove(index) {
      let height = this.$refs["fullPage"].clientHeight; //获取屏幕的宽度
      let scrollPage = this.$refs["fullPageContainer"]; // 获取执行tarnsform的元素
      let scrollHeight; // 计算滚动的告诉，是往上滚还往下滚
      scrollHeight = -(index - 1) * height + "px";
      scrollPage.style.transform = `translateY(${scrollHeight})`;
      this.fullpage.current = index;
    },
    mouseWheelHandle(event) {
      // 监听鼠标监听
      // 添加冒泡阻止
      let evt = event || window.event;
      if (evt.stopPropagation) {
        evt.stopPropagation();
      } else {
        evt.returnValue = false;
      }
      if (this.fullpage.isScrolling) {
        // 判断是否可以滚动
        return false;
      }
      let e = event.originalEvent || event;
      this.fullpage.deltaY = e.deltaY || e.detail; // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next();
      } else if (this.fullpage.deltaY < 0) {
        this.pre();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeVideo); // 在组件销毁前移除事件监听
  },
  mounted() {
    this.resizeVideo(); // 初始化时调整视频大小
    window.addEventListener("resize", this.resizeVideo); // 监听窗口大小变化
    // this.$refs.videoPlayer.addEventListener('canplay', this.playVideo);
    this.getvideoUrl();
  },
};
</script>
<style scoped>
.fullPage {
  padding: 0;
  width: 100%;
  margin: 0;
  height: calc(100vh);
  overflow: hidden;
}

.fullPageContainer {
  width: 100%;
  height: calc(100vh);
  transition: all linear 0.5s;
}

.section {
  width: 100%;
  height: calc(100vh);
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.homeTopImg_wrapper {
  width: 100%;
  height: 100vh;
}

.homeTopImg {
  width: 100%;
  height: 100%;
}

.homeTopTitle_wrapper {
  position: absolute;
  top: 32%;
  left: 16%;
}

.topTitle {
  font-size: 3rem;
  font-family: FZLTXHK;
  font-weight: 400;
  color: rgba(255, 255, 255, 0);
}

.homeTopTitle_wrapper {
  width: 27%;
}

.subTitle {
  font-size: 1.5rem;
  width: 100%;
  margin-top: 5%;
  font-family: FZLTXHK;
  font-weight: 400;
  color: rgba(255, 255, 255, 0);
  line-height: 2.2rem;
}

.homeTop_btn_wrapper {
  position: absolute;
  top: 52%;
  left: 16%;
  width: 8%;
  height: 4%;
  cursor: pointer;
  background: #285cc7;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.2rem;
}

.solution_cover_wrapper {
  width: 100%;
  height: 100vh;
  background: url("../assets/solutionHomeBackground.jpg") no-repeat;
  background-size: cover;
}

.homeSolutionTitle_wrappe {
  padding-top: 10%;
  margin-left: 10%;
  width: 8%;
  height: 5%;
}

.homeSolutionTitleZh {
  font-size: 2.6rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.homeSolutionTitleEn {
  font-size: 2rem;
  font-family: BerlinSansFBDemi;
  letter-spacing: 5px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.solution_category_wrapper {
  width: 40%;
  height: 10%;
  margin-left: 50%;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  font-weight: 400;
  color: #ffffff;
}

.solution_category_detial_img {
  /* margin-top: 3%; */
  margin-left: 10%;
  width: 35%;
  height: 30.07%;
}

.solution_category_detial_img1 {
  width: 100%;
  height: 100%;
}

.solution_category_detial {
  width: 60.7%;
  height: 22.1%;
  margin-left: 15%;
  margin-top: 2.5%;
}

.solution_category_detial_all {
  width: 100%;
  height: 100%;
  margin-left: 58%;
  margin-top: -30%;
}

.solution_category_detial_ZH {
  font-size: 1.8rem;
  font-weight: 400;
  color: #ffffff;
}

.solution_category_detial_EN {
  font-size: 1.2rem;
  font-weight: 400;
  color: #bbbbbb;
}

.solution_category_detial_describe {
  margin-top: 2.5%;
  width: 40%;
  font-size: 1.4rem;
  font-weight: 400;
  color: #bbbbbb;
}

.solution_category_btn_wrapper {
  font-size: 1.2rem;
  font-weight: 400;
  color: #999999;
  margin-left: 58%;
  cursor: pointer;
}

.homeCulturalTourism {
  cursor: pointer;
  width: 23%;
  height: 25%;
  margin-top: -0.6%;
  background: #285cc7;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.drawLinePoint {
  margin-top: 13%;
  margin-left: 10%;
  width: 90%;
  height: 30px;
  display: flex;
}

.point1 {
  width: 7px;
  height: 7px;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  background: #ffffff;
}

.line {
  width: 100%;
  height: 1px;
  display: flex;
  justify-content: center;
  align-self: center;
  background: rgba(225, 225, 225, 0.3);
}

.bigCircle {
  position: absolute;
  margin-left: 30%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-self: center;
}

.point2 {
  width: 7px;
  height: 7px;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  background: #ffffff;
}

.point3 {
  margin-left: 65%;
  position: absolute;
  width: 7px;
  height: 7px;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  background: #ffffff;
}

.point4 {
  position: absolute;
  width: 7px;
  margin-left: 70%;
  height: 7px;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  background: #ffffff;
}

.point5 {
  position: absolute;
  width: 7px;
  height: 7px;
  display: flex;
  margin-left: 75%;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  background: #ffffff;
}

.categories_wrapper {
  width: 100%;
  height: 100vh;
  background: url("../assets/categoriesBackground.jpg") no-repeat;
  background-size: cover;
}

.categories_title_wrapper {
  padding-top: 10%;
  margin-left: 9%;
}

.categories_ZHtitle {
  font-size: 2.6rem;
  font-weight: 400;
  color: #ffffff;
}

.categories_ENtitle {
  margin-top: 1%;
  font-size: 2rem;
  font-family: BerlinSansFBDemi;
  font-weight: bold;
  color: #10e3e9;
  line-height: 36px;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.caterories_btn_wrapper {
  cursor: pointer;
  width: 8.3%;
  height: 4.5%;
  background: #285cc7;
  border-radius: 2rem;
  color: #ffffff;
  margin-top: -25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 46%;
  font-size: 1.2rem;
}

.solution_category_detial_img1 {
  animation: fadeInAnimation ease 1s;
}

.play_full_video_wrapper {
  position: absolute;
  top: 90%;
}
.play_full_video {
  width: 300px;
  height: 70px;
  /* background-color: #10e3e9; */
  border-radius: 50px;
  border: 2px solid #fff;
  margin-left: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.play_video_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.indicator_arrow_wrapper {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 2.5rem;
}

.indicator_arro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.solution_category_detial_all {
  animation: fadeInAnimation ease 1s;
}

.cionXIALA {
  color: #10e3e9;
  line-height: 36px;
  font-weight: 600;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: bounce-inSS 2s infinite;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    /*设置不透明度*/
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce-inSS {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}
</style>
