<template>
  <div class="slide">
    <div class="slide-list">
      <div class="slide-item" @click="enter(0)">
        <div class="imgAndTitle_wrapper">
          <div class="jiange"></div>
          <img class="slide-item-img" :src="titleImgUrl[0]" style="height: 20vh" />
          <div class="imgTitle">
            <div>智享文旅</div>
          </div>
          <div class="case_title">智享文旅</div>
        </div>
        <div class="tringle"></div>
      </div>
      <div class="slide-item" @click="enter(1)">
        <div class="imgAndTitle_wrapper">
          <div class="jiange"></div>
          <img class="slide-item-img" :src="titleImgUrl[1]" style="height: 20vh" />
          <div class="imgTitle">
            <div>政企办公</div>
          </div>
          <div class="case_title">政企办公</div>
        </div>
        <div class="tringle"></div>
      </div>
      <div class="slide-item" @click="enter(2)">
        <div class="imgAndTitle_wrapper">
          <div class="jiange"></div>
          <img class="slide-item-img" :src="titleImgUrl[2]" style="height: 20vh" />
          <div class="imgTitle">
            <div>智慧工业</div>
          </div>
          <div class="case_title">智慧工业</div>
        </div>
        <div class="tringle"></div>
      </div>
      <div class="slide-item" @click="enter(3)">
        <div class="imgAndTitle_wrapper">
          <div class="jiange"></div>
          <img class="slide-item-img" :src="titleImgUrl[3]" style="height: 20vh" />
          <div class="imgTitle">
            <div>智慧城市</div>
          </div>
          <div class="case_title">智慧城市</div>
        </div>
        <div class="tringle"></div>
      </div>
      <div class="slide-item" @click="enter(4)">
        <div class="imgAndTitle_wrapper">
          <div class="jiange"></div>
          <img class="slide-item-img" :src="titleImgUrl[4]" style="height: 20vh" />
          <div class="imgTitle">
            <div>智绘影像</div>
          </div>
          <div class="case_title">智绘影像</div>
        </div>
        <div class="tringle"></div>
      </div>
      <div class="slide-item" @click="enter(5)">
        <div class="imgAndTitle_wrapper">
          <div class="jiange"></div>
          <img class="slide-item-img" :src="titleImgUrl[5]" style="height: 20vh" />
          <div class="imgTitle">
            <div>自然生态</div>
          </div>
          <div class="case_title">自然生态</div>
        </div>
        <div class="tringle"></div>
      </div>
      <div class="slide-item" @click="enter(6)">
        <div class="imgAndTitle_wrapper">
          <div class="jiange"></div>
          <img class="slide-item-img" :src="titleImgUrl[6]" style="height: 20vh" />
          <div class="imgTitle">
            <div>智析医疗</div>
          </div>
          <div class="case_title">智析医疗</div>
        </div>
        <div class="tringle"></div>
      </div>
    </div>
    <div class="case_detail_wrapper">
      <!-- <div v-show="0 === number" class="case_title_wrapper">
        <div class="case_context">
          近年，随着移动互联网的蓬勃发展，每时每刻产生的信息量已经远超个人对信息的解析能力。派动科技致力于通过人工智能在纷繁复杂的信息流中，消除筛选文旅信息的繁琐，凸显出城市的文化特色，让游客沉浸其中。
        </div>
      </div>
      <div v-show="1 === number" class="case_title_wrapper">
        <div class="case_context">
          基于人工智能在图像处理和语义分析方面的卓越性能，以医学影像分割和生物蛋白分子建模为代表，人工智能在疾病的筛查与诊断、基因组学和药物研发等方面，给生物医学的研究提供了新的思路和有力的验证工具。
        </div>
      </div>
      <div v-show="2 === number" class="case_title_wrapper">
        <div class="case_context">
          数字世界，过去的来到现在、现在的走向未来；每一个阶段都要丰富多彩，都要与众不同；用AI的想象力，智绘影像，智绘大千世界。
          随着云计算、5G、区块链等技术的大规模应用，数字基础设施的不断完善，元宇宙概念呼之欲出。在虚拟的数字世界，AI在图像处理、影像的画质渲染与修复方面展现出了非凡的实力。通过目标检测和姿态识别技术，AI极大的扩展了虚拟形象和数字建模的应用范围，在真实与虚拟的联动中占据了重要地位。
        </div>
      </div>
      <div v-show="3 === number" class="case_title_wrapper">
        <div class="case_context">
          基于5G互联网技术和目标检测技术的结合，在路况检测、高空巡检、工业检测等领域，派动科技运用自身优势，在多种环境下实现了数据的实时统计与突发状况的及时辨别。以高效率、高准确性、低成本的方式，解决了在枯燥环境下，重复劳动的问题。
        </div>
      </div>
      <div v-show="4 === number" class="case_title_wrapper">
        <div class="case_context">
          云南省被称为动植物王国，且拥有最多的生态系统类型和生物种类。派动科技融合云计算、大数据、5G、物联网和人工智能技术，着力于野生动物的巡查、检测与统计。推动野生动物保护数字化场景的加速重塑，将美丽的生态环境和丰富的生物物种呈现在大众的视野中。
        </div>
      </div> -->
      <div class="case_detail_context_wrapper">
        <div
          v-show="0 === number"
          class="case_detail_context"
          v-for="(item, index) in list[0]"
          :key="item.ZHtitle"
        >
          <img :src="item.src" class="caseImg" v-if="index % 2 == 0" />
          <div class="case_all_detail">
            <div class="case_title_all_wrapper">
              <div class="ZHCaseTitle">{{ item.ZHtitle }}</div>
              <div class="ENCaseTitle">{{ item.ENtitle }}</div>
            </div>
            <div class="case_context_wrapper">
              <div>{{ item.context }}</div>
            </div>
            <!-- <div class="application_scenario_wrapper">
              <div class="application_title">应用场景：</div>
              <div>{{ item.application }}</div>
            </div> -->
          </div>
          <img
            style="margin-left: 5%"
            :src="item.src"
            class="caseImg"
            v-if="index % 2 != 0"
          />
        </div>
        <div
          v-show="1 === number"
          class="case_detail_context"
          v-for="(item, index) in list[1]"
          :key="item.ZHtitle"
        >
          <img :src="item.src" class="caseImg" v-if="index % 2 == 0" />
          <div class="case_all_detail">
            <div class="case_title_all_wrapper">
              <div class="ZHCaseTitle">{{ item.ZHtitle }}</div>
              <div class="ENCaseTitle">{{ item.ENtitle }}</div>
            </div>
            <div class="case_context_wrapper">
              <div>{{ item.context }}</div>
            </div>
            <!-- <div class="application_scenario_wrapper">
              <div class="application_title">应用场景：</div>
              <div>{{ item.application }}</div>
            </div> -->
          </div>
          <img
            style="margin-left: 5%"
            :src="item.src"
            class="caseImg"
            v-if="index % 2 != 0"
          />
        </div>
        <div
          v-show="2 === number"
          class="case_detail_context"
          v-for="(item, index) in list[2]"
          :key="item.ZHtitle"
        >
          <img :src="item.src" class="caseImg" v-if="index % 2 == 0" />
          <div class="case_all_detail">
            <div class="case_title_all_wrapper">
              <div class="ZHCaseTitle">{{ item.ZHtitle }}</div>
              <div class="ENCaseTitle">{{ item.ENtitle }}</div>
            </div>
            <div class="case_context_wrapper">
              <div>{{ item.context }}</div>
            </div>
            <!-- <div class="application_scenario_wrapper">
              <div class="application_title">应用场景：</div>
              <div>{{ item.application }}</div>
            </div> -->
          </div>
          <img
            style="margin-left: 5%"
            :src="item.src"
            class="caseImg"
            v-if="index % 2 != 0"
          />
        </div>
        <div
          v-show="3 === number"
          class="case_detail_context"
          v-for="(item, index) in list[3]"
          :key="item.ZHtitle"
        >
          <img :src="item.src" class="caseImg" v-if="index % 2 == 0" />
          <div class="case_all_detail">
            <div class="case_title_all_wrapper">
              <div class="ZHCaseTitle">{{ item.ZHtitle }}</div>
              <div class="ENCaseTitle">{{ item.ENtitle }}</div>
            </div>
            <div class="case_context_wrapper">
              <div>{{ item.context }}</div>
            </div>
            <!-- <div class="application_scenario_wrapper">
              <div class="application_title">应用场景：</div>
              <div>{{ item.application }}</div>
            </div> -->
          </div>
          <img
            style="margin-left: 5%"
            :src="item.src"
            class="caseImg"
            v-if="index % 2 != 0"
          />
        </div>
        <div
          v-show="4 === number"
          class="case_detail_context"
          v-for="(item, index) in list[4]"
          :key="item.ZHtitle"
        >
          <img :src="item.src" class="caseImg" v-if="index % 2 == 0" />
          <div class="case_all_detail">
            <div class="case_title_all_wrapper">
              <div class="ZHCaseTitle">{{ item.ZHtitle }}</div>
              <div class="ENCaseTitle">{{ item.ENtitle }}</div>
            </div>
            <div class="case_context_wrapper">
              <div>{{ item.context }}</div>
            </div>
            <!-- <div class="application_scenario_wrapper">
              <div class="application_title">应用场景：</div>
              <div>{{ item.application }}</div>
            </div> -->
          </div>
          <img
            style="margin-left: 5%"
            :src="item.src"
            class="caseImg"
            v-if="index % 2 != 0"
          />
        </div>
        <div
          class="case_detail_context"
          v-for="(item, index) in list[5]"
          :key="item.ZHtitle"
          v-show="5 === number"
        >
          <img :src="item.src" class="caseImg" v-if="index % 2 == 0" />
          <div class="case_all_detail">
            <div class="case_title_all_wrapper">
              <div class="ZHCaseTitle">{{ item.ZHtitle }}</div>
              <div class="ENCaseTitle">{{ item.ENtitle }}</div>
            </div>
            <div class="case_context_wrapper">
              <div>{{ item.context }}</div>
            </div>
            <!-- <div class="application_scenario_wrapper">
              <div class="application_title">应用场景：</div>
              <div>{{ item.application }}</div>
            </div> -->
          </div>
          <img
            style="margin-left: 5%"
            :src="item.src"
            class="caseImg"
            v-if="index % 2 != 0"
          />
        </div>
        <div
          v-show="6 === number"
          class="case_detail_context"
          v-for="(item, index) in list[6]"
          :key="item.ZHtitle"
        >
          <img :src="item.src" class="caseImg" v-if="index % 2 == 0" />
          <div class="case_all_detail">
            <div class="case_title_all_wrapper">
              <div class="ZHCaseTitle">{{ item.ZHtitle }}</div>
              <div class="ENCaseTitle">{{ item.ENtitle }}</div>
            </div>
            <div class="case_context_wrapper">
              <div>{{ item.context }}</div>
            </div>
            <!-- <div class="application_scenario_wrapper">
              <div class="application_title">应用场景：</div>
              <div>{{ item.application }}</div>
            </div> -->
          </div>
          <img
            style="margin-left: 5%"
            :src="item.src"
            class="caseImg"
            v-if="index % 2 != 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImgUrl } from "@/api/getImgUrl";
export default {
  data() {
    return {
      number: this.numb,
      imgCover: 0,
      // active: "",
      selectItem: -1,
      list: [
        //  智享文旅
        [
          {
            src: "",
            ZHtitle: "风景识别",
            ENtitle: "Scenic Recognition",
            context:
              "只需一部手机，派动就可利用深度学习算法，克服天气、光线和遮挡物的干扰，结合多种生动有趣的景点介绍方式，帮您精准识别和定位任意位置与角度的景点，让手机成为您旅行中最好的AI导游。",
          },
          {
            src: "",
            ZHtitle: "运动者图像检索",
            ENtitle: "Athletes Image Retrieval",
            context:
              "基于深度学习的图像检索方法和系统，利用人工智能技术，帮助客户实现对（包括但不限于滑雪者、冲浪者在内的个体）运动过程中的照片与视频进行追踪检测和识别，可帮助各运动者快速准确找到属于自己的精彩瞬间，节约用户选片时间、提高效率。",
          },
        ],
        //  政企办公
        [
          {
            src: "",
            ZHtitle: "文件书写大语言模型定制",
            ENtitle: "Customization Of Large Language Models For Document Writing",
            context:
              "基于派动大语言模型定制化技术，通过对大量特定领域知识和优质文本的输入和学习，开发垂直领域特定需求的大语言模型，实现特定风格的文本自动编写功能。不论是创意性的广告文案、技术性报告、还是任何其他类型的文本，模型都能够以高效、准确的方式完成任务。极大地缩短文档编写时间，保证文本质量，降低人力成本，为客户提供卓越的专业产品和服务。",
          },
          {
            src: "",
            ZHtitle: "智能问答系统和客服",
            ENtitle:
              "Intelligent Question-Answering System And Intelligent Customer Service",
            context:
              "派动拥有自研的 PY-BERT深度学习算法——利用海量的中文语料数据训练出适用于中文的语义特征提取模型。QA问答检索系统领域，该模型可帮助智能问答系统更准确地理解问题语义，更精准检索相关答案；智能客服领域，该模型通过文本或语音与客户进行交互，并提供即时支持、解答常见问题、处理订单等功能，能大幅提升智能客服准确性和效率，从而有效降低人工客服工作量并提高客户满意度。",
          },
          {
            src: "",
            ZHtitle: "舆情报告生成系统",
            ENtitle: "Public Opinion Report Generation System",
            context:
              "基于文本分类和情感分析等自然语言处理技术，智能识别和追踪客户关注的热点话题与突发事件，识别舆论风向、相关产品的用户评论情感态度，并生成舆情分析报告。帮助政府、媒体和企事业单位及时了解自身及自身产品的网络舆情动向，为应对网络舆情突发事件、了解产品用户满意度、调整市场战略提供决策支持。",
          },
          {
            src: "",
            ZHtitle: "金融研报编写",
            ENtitle: "Financial Research Report Writing",
            context:
              "基于数据科学技术、自然语言处理技术(NLP)和自然语言生成技术（NLG），训练金融、经济领域大语言模型，实现（包括但不限于）金融研报的自动编写和生成。通过加速数据处理和分析过程，提供更全面和准确的市场洞察和投资建议。",
          },
        ],
        //  智慧工业
        [
          {
            src: "",
            ZHtitle: "工厂危险行为检测",
            ENtitle: "Factory Hazardous Behavior Detection",
            context:
              "利用对工厂作业行为的监控和分析，派动通过计算机视觉技术，帮助工厂实现危险行为的实时自动检测。一旦发现违反安全条款的行为，系统会自动发出安全警报，提醒工作人员注意危险行为，以预防事故的发生。",
          },
          {
            src: "",
            ZHtitle: "工业缺陷检测",
            ENtitle: "Industrial Defect Detection",
            context:
              "利用目标检测、图像分割和点云等计算机视觉算法，检测工业产品表面的缺陷或不良区域，如划痕、凹陷、裂纹、气泡等。同时，系统可以实现对缺陷的尺寸、形状、位置等信息的计算，并评估缺陷对产品质量的影响，提供质量控制和缺陷修复的依据。",
          },
          {
            src: "",
            ZHtitle: "墙体缺陷检测",
            ENtitle: "Wall Defect Detection",
            context:
              "派动借助无人机获取（包括但不限于）墙体的自然光和红外线照片数据，并应用自研的PY-HSNet深度学习算法，可实时检测墙体是否存在缺陷，并识别缺陷的类别和位置等信息。该自动化检测方法可以有效替代传统的人工检测，提高检测效率和准确性。",
          },
        ],
        //  智慧城市
        [
          {
            src: "",
            ZHtitle: "路况实时监测",
            ENtitle: "Real-Time Traffic Monitoring",
            context:
              "利用人工智能技术，通过行车记录仪等设备对道路上的障碍物、深坑、积水和路面龟裂等危险情况进行实时监测。一旦发现问题，系统会自动拍摄现场照片、视频，并上传到相关部门进行处理，以减少交通事故的发生。",
          },
          {
            src: "",
            ZHtitle: "动作行为检测",
            ENtitle: "Motion Behavior Detection",
            context:
              "利用城市监控等实时视频流，运用人工智能算法进行目标姿态分析。系统可以精准识别车祸发生、老人摔跤、中风跌倒或民众打架斗殴等动作姿态，及时通知相关部门进行处理和救援，提高公共安全和应急响应能力。",
          },
          {
            src: "",
            ZHtitle: "语音识别和语音交互",
            ENtitle: "Speech Recognition And Voice Interaction",
            context:
              "NLP技术可实现语音识别系统，用于识别和转录（包括但不限于）司机和乘客的语音指令或对话。例如驾驶员可借助系统，通过语音与车辆进行交互，实现控制导航、调整音频和娱乐系统等需求，从而提高驾驶安全性和用户体验。",
          },
        ],
        //  智慧影像
        [
          {
            src: "",
            ZHtitle: "AI 绘画",
            ENtitle: "AI Painting",
            context:
              "派动通过融合计算机视觉技术(CV)和自然语言处理技术(NLP)，让用户只需输入文字描述，即可实现艺术作品的自动生成或辅助完成艺术创作，让创作的可能性与表现力进一步拓展，也为艺术家和创作者提供了新的创作工具和灵感来源。",
          },
          {
            src: "",
            ZHtitle: "影像修复与渲染",
            ENtitle: "Image Rendering And Restoration",
            context:
              "基于人工智能技术，对老旧影像进行修复和着色。派动可利用算法对损坏的图像进行修复、插帧和增强，提升图像的质量和可视化效果，使影像更加清晰、真实、充满吸引力。该技术可用于修复古老影像、恢复文化遗产、改进视频游戏图像等领域，帮助人们还原影像和历史原貌，帮助揭开传统文化的历史真相。",
          },
        ],
        //  自然生态
        [
          {
            src: "",
            ZHtitle: "野生保护动物辨别与统计",
            ENtitle: "Identification And Statistics Of Wildlife Conservation Animals",
            context:
              "运用目标检测和运动轨迹检测算法，依托自然保护区的视频监控录像，对野生动物进行实时跟踪与统计，实现对其数量和分布的实时监测，同时，系统还可实现对野生动物的实时直播，帮助相关部门及时了解野生动物动态、为热衷于野生动物保护的民众提供了解其真实情况的渠道与窗口，为野生动物保护知识的普及与野生动物保护效果的提升提供更智能的解决方案。",
          },
          {
            src: "",
            ZHtitle: "环境监测与预警",
            ENtitle: "Environmental Monitoring And Early Warning",
            context:
              "利用传感器网络、物联网、数据分析技术和计算机视觉技术(CV)，实时监测水质、空气质量和土壤质量等环境参数，包括火灾和起火点的检测。通过预测和预警环境变化、生态灾害和疾病爆发，为相关部门做出决策提供科学依据，以便各部门及时获取警情并及时采取保护措施和制定环境管理方案。",
          },
          {
            src: "",
            ZHtitle: "自然资源管理",
            ENtitle: "Natural Resource Management",
            context:
              "结合遥感数据、地理信息系统（GIS）数据和生态监测数据，运用深度学习算法帮助监测和管理自然资源，例如森林、湖泊和海洋，实现资源的可持续利用和保护，帮助有关部门实现自然资源管理智能化、决策科学化。",
          },
        ],
        //  智析医疗
        [
          {
            src: "",
            ZHtitle: "医学影像智能筛查诊断",
            ENtitle: "Intelligent Screening And Diagnosis Of Medical Images",
            context:
              "利用图像分割技术对医学影像进行处理，实现对脑肿瘤或其他部位的肿瘤的快速、准确识别、精准定位、测量和分割，提高医生的诊断效率，降低漏诊或误诊风险，为患者提供更精准的诊断结果和治疗方案。",
          },
          {
            src: "",
            ZHtitle: "医疗数据管理与分析",
            ENtitle: "Management And Analysis of Medical Data",
            context:
              "运用数据科学和自然语言处理技术(NLP)，处理和分析大规模的医疗数据集，包括但不限于医学文献和电子病历等信息源。通过自动化方式从海量数据中提取有用的医学信息，为临床决策和研究提供支持，帮助医生更好更准确地了解患者的病情、制定治疗方案，助力医学科研进展的推动。",
          },
        ],
      ],
      exampleUrlList: [
        ["/solution/culturalTravel/scenic.png", "/solution/culturalTravel/skier.png"],
        [
          "/solution/work/models.png",
          "/solution/work/qa.png",
          "/solution/work/public.png",
          "/solution/work/financial.png",
        ],
        [
          "/solution/industry/factory.png",
          "/solution/industry/indefect.png",
          "/solution/industry/wall.png",
        ],
        [
          "/solution/city/traffic.png",
          "/solution/city/action.png",
          "/solution/city/voice.png",
        ],
        ["/solution/image/painting.png", "/solution/image/image.jpg"],
        [
          "/solution/ecology/wildlife.png",
          "/solution/ecology/environment.png",
          "/solution/ecology/monitoring.png",
        ],
        [
          "/solution/medicalTreatment/medical.png",
          "/solution/medicalTreatment/analysis.png",
        ],
      ],
      titleImgUrl: [],
      titleImgUrlList: [
        "/solution/culturalTravel/travel.png",
        "/solution/work/work.png",
        "/solution/industry/industry.png",
        "/solution/city/city.png",
        "/solution/image/image.png",
        "/solution/ecology/ecology.png",
        "/solution/medicalTreatment/medicalTreatment.png",
      ],
    };
  },
  props: {
    numb: {
      type: Number,
      require: false,
      default: 0,
    },
  },
  mounted() {
    this.getTitleImg();
    this.getExampleImg();
    document.getElementsByClassName("slide-item-img")[0].style.opacity = "0.36";
    document.getElementsByClassName("slide-item-img")[1].style.opacity = "0.36";
    document.getElementsByClassName("slide-item-img")[2].style.opacity = "0.36";
    document.getElementsByClassName("slide-item-img")[3].style.opacity = "0.36";
    document.getElementsByClassName("slide-item-img")[4].style.opacity = "0.36";
    document.getElementsByClassName("slide-item-img")[5].style.opacity = "0.36";
    document.getElementsByClassName("slide-item-img")[6].style.opacity = "0.36";
    document.getElementsByClassName("case_title")[0].style.visibility = "hidden";
    document.getElementsByClassName("case_title")[1].style.visibility = "hidden";
    document.getElementsByClassName("case_title")[2].style.visibility = "hidden";
    document.getElementsByClassName("case_title")[3].style.visibility = "hidden";
    document.getElementsByClassName("case_title")[4].style.visibility = "hidden";
    document.getElementsByClassName("case_title")[5].style.visibility = "hidden";
    document.getElementsByClassName("case_title")[6].style.visibility = "hidden";
    //   document.getElementsByClassName("tringle")[0].style.visibility =
    //   "hidden";
    // document.getElementsByClassName("tringle")[1].style.visibility =
    //   "hidden";
    // document.getElementsByClassName("tringle")[2].style.visibility =
    //   "hidden";
    // document.getElementsByClassName("tringle")[3].style.visibility =
    //   "hidden";
    // document.getElementsByClassName("tringle")[4].style.visibility =
    //   "hidden";
    this.enter(this.numb);
  },
  methods: {
    enter(n) {
      n = n === undefined || isNaN(n) || n === null ? 2 : n;
      this.number = n;
      let array = document.getElementsByClassName("tringle");
      // let canvas = document.getElementsByClassName("canvas")
      // let ctx = canvas[n].getContext('2d')

      if (this.selectItem >= 0) {
        document.getElementsByClassName("slide-item-img")[this.selectItem].style.opacity =
          "0.36";
        document.getElementsByClassName("imgTitle")[this.selectItem].style.display =
          "block";
        document.getElementsByClassName("slide-item-img")[this.selectItem].style.border =
          "none";
        document.getElementsByClassName("slide-item")[this.selectItem].style.width =
          "10%";
        array[this.selectItem].style.visibility = "hidden";
        document.getElementsByClassName("case_title")[this.selectItem].style.visibility =
          "hidden";
      }

      document.getElementsByClassName("slide-item-img")[n].style.opacity = "1";
      // document.getElementsByClassName("slide-item-img")[n].style.border =
      //   "1px solid #ffffff";
      document.getElementsByClassName("slide-item")[n].style.width = "20%";
      // console.log(array);
      array[n].style.visibility = "visible";
      array[n].style.width = "0px";
      array[n].style.height = "0px";
      // array[n].style.border = "1rem solid #ffffff";
      array[n].style.borderBottomColor = "transparent";
      array[n].style.borderLeftColor = "transparent";
      array[n].style.borderRightColor = "transparent";
      array[n].style.marginLeft = "45%";
      array[n].style.marginTop = "-3.5%";
      document.getElementsByClassName("imgTitle")[n].style.display = "none";
      document.getElementsByClassName("case_title")[n].style.visibility = "visible";
      // document.getElementsByClassName("case_title")[n].style.text_align = "center";
      this.selectItem = n;
    },
    getTitleImg() {
      for (let i = 0; i < this.titleImgUrlList.length; i++) {
        getImgUrl(this.titleImgUrlList[i]).then((res) => {
          if (res.data.code == 200) {
            this.$set(this.titleImgUrl, i, res.data.data); // 动态设置响应式图片URL
          }
        });
      }
    },
    getExampleImg() {
      // 遍历每个大类
      for (let i = 0; i < this.exampleUrlList.length; i++) {
        // 遍历每个小类中的图片链接
        for (let j = 0; j < this.exampleUrlList[i].length; j++) {
          // 获取图片URL的异步操作
          getImgUrl(this.exampleUrlList[i][j]).then((res) => {
            // 判断返回的状态码是否为200
            if (res.data.code == 200) {
              // 动态设置响应式图片URL
              this.$set(this.list[i][j], "src", res.data.data);
            }
          });
        }
      }
    },
  },
};
</script>

<style scoped media="screen">
.slide {
  width: 100%;
  /* height: 132vw; */
  margin-bottom: 15vh;
}

.slide-list {
  display: flex;
  margin-top: 2.5%;
  width: 100%;
  /* height: 15.5%; */
}

/* .case_title{
  margin-top: -2.5%;
} */

.slide-item {
  width: 10%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s linear;
  margin-right: 5%;
  position: relative;
  z-index: 1;
}

.slide-item > img {
  opacity: 0.6;
}
.imgAndTitle_wrapper {
  /* font-size: 1.5rem; */
  width: 100%;
  height: 100%;
}

/* .slide-item > img:hover {
  opacity: 1;
} */
.imgTitle {
  display: block;
  margin-top: -5%;
  margin-left: 3.5%;
  z-index: 99;
  width: 100%;
  position: absolute;
  top: 50%;
  font-size: 1.4rem;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.case_detail_wrapper {
  width: 96%;
  /* height: 85%; */
  margin-left: 2%;
  margin-top: 0.5%;
  background: rgba(225, 225, 225, 0.04);
}

.case_title_wrapper {
  padding-top: 1%;
  width: 95%;
  margin-left: 2.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.case_title {
  font-size: 1.8rem;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.case_context {
  font-size: 1.1rem;
  margin-top: 1%;
  font-weight: 400;
  color: #ffffff;
  line-height: 180%;
  letter-spacing: 2px;
  /* position: absolute; */
}

.case_detail_context_wrapper {
  width: 96%;
  /* height: 17.72vw; */
  margin-left: 2%;
  margin-top: 5%;
}

.case_detail_context {
  width: 100%;
  margin-top: 2.5%;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
}
.jiange {
  height: 0.5625vw;
}

.caseImg {
  width: 51.2%;
  /* height: 25vw; */
}

.case_all_detail {
  width: 43.8%;
  /* height: 17.72vw; */
  margin-left: 5%;
}

.ZHCaseTitle {
  font-size: 1.9rem;
  font-weight: 600;
  color: #ffffff;
  margin-top: 15%;
  font-style: italic;
}

.ENCaseTitle {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.case_context_wrapper {
  font-size: 1.2rem;
  font-weight: 400;
  color: #ffffff;
  margin-top: 10%;
  line-height: 180%;
  letter-spacing: 2px;
}

.application_scenario_wrapper {
  font-size: 1.2rem;
  font-weight: 400;
  color: #ffffff;
  margin-top: 5%;
  display: flex;
}
.case_context {
  animation: fadeInAnimation ease 1s;
}
.caseImg {
  animation: fadeInAnimation ease 1s;
}
.ZHCaseTitle {
  animation: fadeInAnimation ease 1s;
}
.ENCaseTitle {
  animation: fadeInAnimation ease 1s;
}
.case_context_wrapper {
  animation: fadeInAnimation ease 1s;
}
.application_scenario_wrapper {
  animation: fadeInAnimation ease 1s;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0; /*设置不透明度*/
  }

  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 1920px) {
  .case_title {
    font-size: 1.1rem;
  }
}
</style>
