//引入axios
import axios from "axios"
let baseURL = process.env.VUE_APP_SERVER_PATH
let imgBaseUrl = process.env.VUE_APP_IMG_PATH
//创建axios实例
export const request = axios.create({
  // timeout: 1000,//请求超时时间
  headers: { 'Content-Type': 'application/json' }//请求头，可以不写
});
// 添加请求拦截器
request.interceptors.request.use(function (config) {
  config.baseURL = config.isImg ? imgBaseUrl : baseURL;
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

// //导出axios实例
// export default request