<template>
  <div class="scroll_wrapper">
    <navigatorBar
      style="background: rgba(0, 0, 0, 0.5); z-index: 999999999"
    ></navigatorBar>
    <!-- <i class="el-icon-chat-dot-round" style="position: fixed;z-index: 999999999;color: white;font-size: 40px;top: 90%;left: 95%;"></i> -->
    <div class="solution_main_wrapper">
      <div class="solutionBackground_wrapper">
        <img :src="require('../../public/images/technicalTopBackground.webp')" class="solutionBackgroundImg" />
      </div>
      <div class="techTitle_wrapper">
        <div class="techZHTitle">解决方案</div>
        <div class="techENTitle">SOLUTION</div>
      </div>
      <div class="solutionMainContext_wrapper">
        <!-- 面包屑 -->
        <!-- <div class="breadcrumb">
        <div class="breadcrumb-span">
          您的位置：首页
          <span style="color: #999999">></span>
          <span style="color: #10e4e9">解决方案</span>
        </div>
        <div class="bread-divider"/>
      </div> -->
        <!-- 轮播图 -->
        <solution-accordion :numb="Number(n)"></solution-accordion>
      </div>
    </div>
    <bottom style="z-index: 99"></bottom>
  </div>
</template>

<script>
import { getImgUrl } from "@/api/getImgUrl";
import navigatorBar from "../components/NavigatorBar.vue";
import bottom from "../components/Bottom.vue";
import solutionAccordion from "../components/SolutionAccordion.vue";
export default {
  data() {
    return {
      n: 2,
      imgBack: "",
    };
  },
  components: {
    navigatorBar,
    bottom,
    solutionAccordion,
  },
  created() {
    if (this.$route.query.number) {
      this.n = this.$route.query.number;
    }
    this.getBackImg();
  },
  methods: {
    async getBackImg() {
      await getImgUrl("/technicalTopBackground.png").then((res) => {
        if (res.data.code == 200) {
          this.imgBack = res.data.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.solution_main_wrapper {
  width: 100vw;
  /* height: 113vw; */
}

.solutionBackground_wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.solutionBackgroundImg {
  width: 100%;
  height: 100%;
}

.techTitle_wrapper {
  margin-top: 7.554vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.techZHTitle {
  font-size: 2.5rem;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #ffffff;
  font-style: italic;
}

.techENTitle {
  font-size: 1.2rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  margin-top: 0.675vw;
  color: #000000;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.solutionMainContext_wrapper {
  width: 90%;
  /* height: 100%; */
  margin: auto;
  /*border: white solid;*/
}

.breadcrumb {
  display: flex;
  flex-wrap: nowrap;
  color: #ffffff;
  font-size: 1.2rem;
  width: 100%;
  height: 1.5%;
  margin-top: 1.96875vw;
}

.bread-divider {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 84%;
  background-color: #506185;
  height: 1px;
  margin-top: -0.9%;
  margin-left: 2%;
}
</style>
