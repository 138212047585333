import {request} from "@/requests/index"

export const submitMsg = (val) => {
    return request({
      url: "/websites/resume/submit",
      method: "post",
      data: val,
      isImg: false,
    });
  };

export const getPositionList = (val) => {
    return request({
      url: "/websites/recruitment/list",
      method: "post",
      data: val,
      isImg: false,
    });
  };