<template>
  <div>
    <div class="main-contain">
      <div class="back"></div>
      <!-- <navigatorBar style="background: rgba(0,0,0,0.5); z-index: 999999999" /> -->
      <!-- <i class="el-icon-chat-dot-round" style="position: fixed;z-index: 999999999;color: white;font-size: 40px;top: 90%;left: 95%;"></i> -->
      <!-- <div class="title-area">
        <div class="techTitle_wrapper">
        <div class="techZHTitle">加入我们</div>
        <div class="techENTitle">JOIN US</div>
      </div>
        <div style="font-size: 2.5rem">加入我们</div>
        <div style="font-size: 1.2rem; margin-top: 0.675vw">JOIN US</div>
      </div> -->
      <div class="company_intrduce_title">
        <div class="company_intrduce_titleZH">加入我们</div>
        <div class="company_intrduce_titleEN">JOIN US</div>
      </div>
      <div class="content-area">
        <!-- 面包屑 -->
        <!-- <div class="breadcrumb">
          <div class="breadcrumb-span">
            您的位置：首页
            <span style="color: #999999">></span>
            <span style="color: #10e4e9"> 加入我们</span>
          </div>
          <div class="bread-divider" />
        </div> -->
        <div class="position_all_wrapper">
          <div id="css">
            <div v-for="(item, i) in positionList" :key="i">
              <div class="title" @mouseenter="btn(i, item.showContent)">
                <div class="position_des_wrapper">
                  <div class="position_title">
                    {{ item.postName }}
                  </div>
                  <div class="position_salary_wrapper">
                    <div class="salary_title">薪资：</div>
                    <div class="salay_wrapper">
                      <div class="department_name">
                        {{ item.salaryMin === -1 ? "面议" : item.salaryMin }}
                      </div>
                      <div v-show="item.salaryMax !== null">-</div>
                      <div class="department_name">
                        {{ item.salaryMax === -1 ? "面议" : item.salaryMax }}
                      </div>
                    </div>
                  </div>
                  <div class="position_address">地点：{{ item.workLocation }}</div>
                </div>
                <template v-if="item.showContent">
                  <span class="el-icon-arrow-up"> </span>
                </template>
                <template v-else>
                  <span class="el-icon-arrow-down"> </span>
                </template>
              </div>
              <!--v-show配合标签的隐藏和显示-->
              <transition name="slide-fade">
                <div class="container" v-show="item.showContent">
                  <div class="position_request">
                    <div class="position_request_left">
                      <div class="position_request_left_title">任职要求：</div>
                      <div
                        class="position_request_left_des"
                        v-html="item.postRequirement"
                      ></div>
                    </div>
                    <div class="responsibilities_wrapper">
                      <div class="responsibilities_title">岗位职责：</div>
                      <div class="responsibilities_one" v-html="item.postDescribe"></div>
                    </div>
                  </div>
                  <div class="position_btn_wrapper">
                    <div class="btn" @click="getResume(item)">申请岗位</div>
                  </div>
                </div>
              </transition>
            </div>
            <!-- <div class="pagination_wrapper" style="display: flex;float: right;padding-top: 20px;">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" style="color: white;"
                :current-page.sync="currentPage3" :page-size="100" layout="prev, pager, next, jumper" :total="1000">
              </el-pagination>
            </div> -->
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagination-contain">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <bottom /> -->
    </div>

    <!-- 投递简历弹窗 -->
    <el-dialog :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth">
          <el-input v-model="form.phone"> </el-input>
        </el-form-item>
        <el-form-item label="邮箱" :label-width="formLabelWidth">
          <el-input v-model="form.email"> </el-input>
        </el-form-item>
        <el-form-item label="职位" :label-width="formLabelWidth">
          <el-input v-model="form.position"></el-input>
        </el-form-item>
        <el-form-item label="简历" :label-width="formLabelWidth">
          <el-upload
            ref="upload"
            class="upload-demo"
            :action="actionUrl"
            name="resumeFile"
            :on-success="upSuccess"
            :on-error="upFail"
            :on-progress="updateFile"
            :before-upload="upBefore"
            :limit="1"
            accept=".pdf,.doc,.docx"
            multiple
            drag
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">
              只能上传<span style="color: red">pdf/word</span>文件，且文件大小不超过10MB
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="工作年限" :label-width="formLabelWidth">
          <el-input v-model="form.workYears" type="number"> </el-input>
        </el-form-item>
        <el-form-item label="期望薪资" :label-width="formLabelWidth">
          <el-input v-model="form.salary">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import navigatorBar from "@/components/NavigatorBar";
import bottom from "@/components/Bottom";
import { getPositionList, submitMsg } from "../api/contact";
import { Loading } from "element-ui";
let loadingInstance;

export default {
  name: "AboutUs",
  components: {
    navigatorBar,
    bottom,
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      pageSize: 5,
      // 投递简历的弹框
      dialogFormVisible: false,
      formLabelWidth: "120px",
      pickerOptions: {
        disabledDate(date) {
          // 禁用 1980 年之前的年份
          return date.getFullYear() < 1980;
        },
      },
      form: {
        fileUrl: "",
        name: "",
        phone: "",
        email: "",
        position: "",
        workYears: 0,
        salary: "",
      },

      positionList: [],
    };
  },
  computed: {
    actionUrl() {
      return process.env.VUE_APP_SERVER_PATH + "/websites/resume/upload";
    },
  },
  mounted() {
    this.getPositionData();
  },
  methods: {
    async getPositionData() {
      // 获取招聘信息列表
      await getPositionList({
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.positionList = res.data.rows;
          this.total = res.data.total;
          let reg = new RegExp("\\n", "g");
          this.positionList.map((p) => {
            p.postDescribe = p.postDescribe.replace(reg, "<br/>");
            p.postRequirement = p.postRequirement.replace(reg, "<br/>");
            p.showContent = false;
          });
        }
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getPositionData();
    },
    // 点击申请岗位出现弹框
    getResume(row) {
      this.dialogFormVisible = true;
      this.form.recId = row.id;
    },
    // 点击展示详细的岗位信息
    btn(index, showContents) {
      this.positionList = this.positionList.map((item, i) => {
        if (index == i) {
          document.getElementsByClassName("title")[i].style =
            "background-color: rgba(0, 0, 0, 0.8)";
          return {
            ...item,
            showContent: !showContents,
          };
        } else {
          document.getElementsByClassName("title")[i].style =
            "background-color: rgba(0, 0, 0, 0.4)";
          return {
            ...item,
            showContent: false,
          };
        }
      });
      const bc = "background-color: rgba(0, 0, 0, " + (showContents ? "0.4)" : "0.8)");
      document.getElementsByClassName("title")[index].style = bc;
    },
    // 上传简历成功
    upSuccess(res) {
      loadingInstance.close();
      console.log(res);

      this.form.fileUrl = res.data;
    },
    // 上传简历失败
    upFail(error) {
      this.$message({
        message: "上传失败，请重新上传",
        type: "error",
        duration: 5000,
        offset: 150,
      });
    },
    upBefore(file) {
      let fileExt = file.name.split(".")[1];
      let fileType = ["pdf", "doc", "docx"].indexOf(fileExt) === -1;
      if (fileType) {
        this.$message({
          message: "请上传pdf或者wod格式文件！",
          type: "error",
          duration: 5000,
          offset: 150,
        });
        return false;
      }
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        this.$message({
          message: "上传文件过大，请重新上传",
          type: "error",
          duration: 5000,
          offset: 150,
        });
        return false;
      }
    },
    updateFile() {
      loadingInstance = Loading.service({ fullscreen: true });
    },
    // 提交弹框信息给后台
    async submit() {
      await submitMsg(this.form).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg ? res.data.msg : "提交成功",
            type: "success",
            duration: 5000,
            offset: 150,
          });
          this.dialogFormVisible = false;
          this.form = {};
        } else {
          this.$message({
            message: "提交失败，请重试",
            type: "error",
            duration: 5000,
            offset: 150,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.main-contain {
  font-family: "Microsoft YaHei", serif;
  /* height: 61.875vw; */
  width: 100%;
  margin-top: 50px;
}

.back {
  z-index: 0;
  position: fixed;
  /* top: 100vh; */
  left: 0;
  height: 100%;
  width: 100%;
  background: url("../assets/joinBackground.jpg") no-repeat;
  background-size: 100% 100%;
}

.company_intrduce_title {
  padding-bottom: 5px;
  width: 10%;
  margin-left: 10%;
  margin-bottom: 30px;
  border-bottom: 1px solid #4e5053;
}
.company_intrduce_titleZH {
  font-size: 2rem;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #ffffff;
  font-style: italic;
}
.company_intrduce_titleEN {
  font-size: 1rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content-area {
  width: 80%;
  margin: auto;
  /*border: white solid;*/
}

.breadcrumb {
  display: flex;
  flex-wrap: nowrap;
  color: #ffffff;
  font-size: 1.2rem;
  width: 100%;
  height: 1.5%;
  margin-top: 1.96875vw;
}

.breadcrumb-span {
  width: 20%;
}

.bread-divider {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 84%;
  background-color: #506185;
  height: 1px;
  margin-top: 0.9%;
  margin-left: 2%;
}

.position_all_wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
  align-content: flex-start;
  margin-top: 2.5%;
}

.apply_btn_wrapper {
  width: 20%;
  height: 12%;
  background: linear-gradient(129deg, #10e4e9, #5489fa);
  font-size: 1.6rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.5%;
  margin-left: 40%;
}

.salay_wrapper {
  display: flex;
}

#css {
  width: 100%;
  color: white;
  margin-bottom: 20px;
  /* height: 20vh; */
}

#css .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #ccc; */
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0 1em;
  margin-top: 15px;
  width: 98%;
  cursor: pointer;
}

.position_des_wrapper {
  display: flex;
  width: 100%;
  /* justify-content: space-around; */
}

.position_title {
  color: #0cc5ca;
  font-size: 1.5rem;
  font-weight: 600;
  width: 25%;
}

.position_salary_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  font-size: 1.2rem;
}

.position_request_left_title {
  color: #6f7c92;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1% 0;
}

.position_request_left_des {
  line-height: 180%;
  letter-spacing: 2px;
}

.responsibilities_one {
  line-height: 180%;
  letter-spacing: 2px;
}

.responsibilities_title {
  color: #6f7c92;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1% 0;
}

#css .title .position_des_wrapper {
  line-height: 6;
  margin: 0;
}

.position_address {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  font-size: 1.2rem;
}

.position_request {
  display: flex;
  width: 100%;
}

#css .container {
  width: 98%;
  /* border: 1px solid #ccc; */
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0 1em;
  margin-bottom: 15px;
}

.position_request_left {
  width: 49%;
}

.responsibilities_wrapper {
  margin-left: 2%;
  width: 49%;
}

.position_btn_wrapper {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2.5%;
}

.btn {
  width: 10%;
  line-height: 4;
  letter-spacing: 2px;
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5%;
  background: linear-gradient(129deg, #10e4e9, #5489fa);
  cursor: pointer;
}

#css .slide-fade-enter-active {
  transition: all 0.3s ease;
}

#css .slide-fade-enter,
#css .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.pagination-contain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-bottom: 20px;
}
:deep(.el-pagination .btn-next) {
  background: transparent;
  color: white;
}
:deep(.el-pagination .btn-prev) {
  background: transparent;
  color: white;
}
:deep(.el-pager li) {
  background: transparent;
}
:deep(.el-pagination__jump) {
  color: white;
}
:deep(.el-pager li.btn-quickprev) {
  color: white;
}
:deep(.el-pager li.btn-quicknext) {
  color: white;
}
:deep(.el-pagination) {
  color: white;
}

:deep(.el-upload__tip) {
  margin-left: 0;
}

:deep(.el-upload) {
  margin-left: 0;
}
</style>
