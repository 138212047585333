<template>
  <div class="scroll_wrapper">
    <navigatorBar
      style="background: rgba(0, 0, 0, 0.5); z-index: 999999999"
    ></navigatorBar>
    <!-- <i class="el-icon-chat-dot-round" style="position: fixed;z-index: 999999999;color: white;font-size: 40px;top: 90%;left: 95%;"></i> -->
    <div class="solution_main_wrapper">
      <div class="solutionBackground_wrapper">
        <img :src="require('../../public/images/cooPartnerHomeBackground.webp')" class="solutionBackgroundImg" />
      </div>
      <div class="techTitle_wrapper">
        <div class="techZHTitle">派动技术</div>
        <div class="techENTitle">TECHNOLOGY</div>
      </div>
      <div class="pydance_technology_wrapper">
        <div class="pydance_technology_select_wrapper">
          <div class="pydance_technology_select">
            <div @click="select(1)" :class="flag == 1 ? 'active' : ''">自然语言处理</div>
            <div @click="select(0)" :class="flag == 0 ? 'active' : ''">计算机视觉</div>
          </div>
        </div>
        <div class="technical_details_introduction_wrapper">
          <div class="technical_details_introduction" v-if="flag == 0">
            <div class="technical_detials">
              <video
                controlslist="nodownload noremoteplayback"
                class="technical_video"
                :src="videoUrl[0]"
                :controls="false"
                loop
                :autoplay="true"
                muted
              ></video>
              <!-- <img v-lazy="require('../assets/tech/cv/target.gif')" class="technical_video" /> -->
              <div class="technical_detials_discrip_wrapper">
                <div class="technical_detials_discrip">
                  <div class="technical_detials_titleZH">目标检测</div>
                  <div class="technical_detials_titleEN">OBJECT DETECTION</div>
                  <div
                    style="
                      margin-top: 50px;
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    目标检测旨在自动识别图像或视频中存在的特定目标，并准确标记出其位置。在目标检测中，计算机需要找到图像中的目标，并用矩形边界框框出目标的位置和大小，同时确定目标的类别。
                  </div>
                  <div
                    style="
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    目标检测区别于简单的图像分类任务，后者只需要确定整张图像的类别，而目标检测需要定位图像中可能存在的多个目标，并识别它们的类别，使计算机能够准确地找到并理解图像中的重要物体或目标。
                  </div>
                </div>
              </div>
            </div>
            <div class="technical_detials">
              <div class="technical_detials_discrip_wrapper">
                <div class="technical_detials_discrip">
                  <div class="technical_detials_titleZH">图像分割</div>
                  <div class="technical_detials_titleEN">IMAGE SEGMENTATION</div>
                  <div
                    style="
                      margin-top: 50px;
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    图像分割旨在将图像中的像素划分为若干个具有语义意义的区域或对象，其目标是将图像中不同的物体或区域从背景中分离出来，以便更好地理解和处理图像内容。
                  </div>
                  <div
                    style="
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    图像分割在许多应用领域中都具有重要的应用价值，如自动驾驶，医学影像分析、图像编辑等。它为计算机视觉任务提供了更精细和准确的信息，使计算机能够更好地理解和解释图像内容。
                  </div>
                </div>
              </div>
              <video
                controlslist="nodownload noremoteplayback"
                class="technical_video"
                :src="videoUrl[1]"
                style="margin-left: 30px"
                :controls="false"
                loop
                :autoplay="true"
                muted
              ></video>
              <!-- <img v-lazy="require('../assets/tech/cv/imageSegmentation.gif')" class="technical_video"> -->
            </div>
            <div class="technical_detials">
              <video
                controlslist="nodownload noremoteplayback"
                class="technical_video"
                :src="videoUrl[2]"
                :controls="false"
                loop
                :autoplay="true"
                muted
              ></video>
              <!-- <img v-lazy="require('../assets/tech/cv/keyPoint.gif')" class="technical_video"> -->
              <div class="technical_detials_discrip_wrapper">
                <div class="technical_detials_discrip">
                  <div class="technical_detials_titleZH">人体关键点识别</div>
                  <div class="technical_detials_titleEN">
                    HUMAN BODY KEYPOINT RECOGNITION
                  </div>
                  <div
                    style="
                      margin-top: 50px;
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    人体关键点识别旨在从图像或视频中准确地检测和标记出人物的关键点位置，如眼睛、鼻子、嘴巴、手腕、
                    脚踝、肩膀、胯部等。
                  </div>
                  <div
                    style="
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    人体关键点识别在很多应用中都具有重要的作用，例如人脸识别、人体姿态分析、动作捕捉、虚拟现实等，还可以用于改善图像质量、人机交互、运动跟踪等方面。
                  </div>
                </div>
              </div>
            </div>
            <div class="technical_detials">
              <div class="technical_detials_discrip_wrapper">
                <div class="technical_detials_discrip">
                  <div class="technical_detials_titleZH">生成对抗</div>
                  <div class="technical_detials_titleEN">
                    GENERATIVE ADVERSARIAL NETWORKS
                  </div>
                  <div
                    style="
                      margin-top: 50px;
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    生成对抗是一种特殊的技术，就像是一个游戏，有两个对手在相互竞争。其中一个对手是生成器，它的目标是制造看起来很真实的假图片，另一个对手是判别器，它的任务是辨别图片是真的还是假的。生成器不断努力生成越来越逼真的图片，而判别器则努力变得越来越聪明，能够更好地辨别哪些图片是真的，哪些是生成器做的。通过这个竞争过程，生成器学习如何制造更真实的图片，而判别器学会更准确地分辨真假。最终使得生成器可以生成非常逼真的图片。
                  </div>
                </div>
              </div>
              <video
                controlslist="nodownload noremoteplayback"
                class="technical_video"
                :src="videoUrl[3]"
                style="margin-left: 30px"
                :controls="false"
                loop
                :autoplay="true"
                muted
              ></video>
              <!-- <img v-lazy="require('../assets/tech/cv/generative.gif')" class="technical_video"> -->
            </div>
          </div>
          <div class="technical_details_introduction" v-if="flag == 1">
            <div class="technical_detials">
              <video
                controlslist="nodownload noremoteplayback"
                class="technical_video"
                style="margin-left: 25px"
                :controls="false"
                loop
                :autoplay="true"
                muted
                :src="videoUrl[4]"
              ></video>
              <div class="technical_detials_discrip_wrapper">
                <div class="technical_detials_discrip">
                  <div class="technical_detials_titleZH">大语言模型定制化开发</div>
                  <div class="technical_detials_titleEN">
                    LARGE LANGUAGE MODEL CUSTOMIZATION DEVELOPMENT
                  </div>
                  <div
                    style="
                      margin-top: 50px;
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    大语言模型定制化开发，是指在特定的领域或行业中训练和优化大型语言模型，使其具备更高的领域专业性和实用性。派动团队基于前沿的大型语言模型架构，利用特定领域构建的定制化数据集,结合Instruction
                    Fine-tuning、Parameter-efficient
                    Fine-tuning等先进技术，在较短时间内调优大语言模型,以适应特定领域的任务需求。重点关注垂直领域的专业知识和特定语言表达，让通用的大模型转变为客户所在领域的专家。
                  </div>
                </div>
              </div>
            </div>
            <div class="technical_detials">
              <div class="technical_detials_discrip_wrapper">
                <div class="technical_detials_discrip">
                  <div class="technical_detials_titleZH">信息提取</div>
                  <div class="technical_detials_titleEN">INFORMATION EXTARCTION</div>
                  <div
                    style="
                      margin-top: 50px;
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    信息抽取是一种基于深度学习、自然语言处理和知识图谱等技术的方法，能够从非结构化文本中迅速、精确地提取出实体和事件等关键信息，并抽取实体之间的逻辑关系。我们的技术能够有效地提高用户获取所需信息的效率和准确性，帮助其更快地获取所需信息。
                  </div>
                </div>
              </div>
              <video
                style="margin-left: 30px"
                controlslist="nodownload noremoteplayback"
                class="technical_video"
                :src="videoUrl[5]"
                :controls="false"
                loop
                :autoplay="true"
                muted
              ></video>
            </div>
            <div class="technical_detials">
              <video
                controlslist="nodownload noremoteplayback"
                class="technical_video"
                style="margin-left: 25px"
                :src="videoUrl[6]"
                :controls="false"
                loop
                :autoplay="true"
                muted
              ></video>
              <div class="technical_detials_discrip_wrapper">
                <div class="technical_detials_discrip">
                  <div class="technical_detials_titleZH">文本分类</div>
                  <div class="technical_detials_titleEN">TEXT CLASSIFICATION</div>
                  <div
                    style="
                      margin-top: 50px;
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    文本分类技术基于深度学习算法，模型从海量数据中学习特征，能够自动对文本数据进行准确地分类，例如基于主题、情感、意图等维度。我们的技术可以帮助用户更好地理解文本数据的结构和特征，为用户提供更加智能化的数据分析服务。
                  </div>
                </div>
              </div>
            </div>
            <div class="technical_detials">
              <div class="technical_detials_discrip_wrapper">
                <div class="technical_detials_discrip">
                  <div class="technical_detials_titleZH">机器翻译</div>
                  <div class="technical_detials_titleEN">MACHINE TRANSLATION</div>
                  <div
                    style="
                      margin-top: 50px;
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 180%;
                      letter-spacing: 2px;
                    "
                  >
                    机器翻译技术利用神经机器翻译模型，能够快速、准确地将多种语言之间的文本进行翻译。我们的技术具有自学习能力，能够根据特定领域的数据和用户反馈不断优化翻译效果。我们的机器翻译技术可以帮助用户轻松跨越语言障碍，实现全球化业务。
                  </div>
                </div>
              </div>
              <video
                style="margin-left: 30px"
                controlslist="nodownload noremoteplayback"
                class="technical_video"
                :controls="false"
                loop
                :src="videoUrl[7]"
                :autoplay="true"
                muted
              ></video>
            </div>
          </div>
          <div class="user_examples_wrapper">
            <div class="user_examples">
              <div class="techTitle_wrapper" style="margin-top: 5vw; margin-bottom: 50px">
                <div class="techZHTitle">用户实例</div>
                <div class="techENTitle">USER INSTANCE</div>
              </div>
            </div>
          </div>

          <!-- <swiper></swiper> -->
        </div>
      </div>
    </div>
    <div class="userInstance_wrapper">
      <div class="userInstance" v-for="(item, index) in instanceList" :key="index">
        <img :src="item.src" class="userInstanceImg" @click="getDetail(index)" />
        <div>{{ item.title }}</div>
      </div>
      <!-- <div class="userInstance">
        <img
          src="../assets"
          class="userInstanceImg"
          @click="getDetail(1)"
        />
        <div>游戏舆情分析及报告</div>
      </div>
      <div class="userInstance">
        <img
          src="../assets"
          class="userInstanceImg"
          @click="getDetail(2)"
        />
        <div>工厂安全行为检测</div>
      </div>
      <div class="userInstance">
        <img
          src="../assets"
          class="userInstanceImg"
          @click="getDetail(3)"
        />
        <div>滑雪者图像检索</div>
      </div>
      <div class="userInstance">
        <img
          src="../assets"
          class="userInstanceImg"
          @click="getDetail(4)"
        />
        <div>政企舆情分析</div>
      </div> -->
    </div>
    <bottom style="z-index: 99; margin-top: 50px"></bottom>
  </div>
</template>

<script>
import { getImgUrl } from "@/api/getImgUrl";
import navigatorBar from "../components/NavigatorBar.vue";
import bottom from "../components/Bottom.vue";
import swiper from "../components/CvSwiper.vue";
export default {
  data() {
    return {
      flag: 1,
      imgBack: "",
      instanceBaseUrlList: [
        "/instance/wall.png",
        "/instance/game.png",
        "/instance/factory.png",
        "/instance/player.png",
        "/instance/government.png",
      ],
      instanceList: [
        {
          src: "",
          title: "墙体缺陷检测",
        },
        {
          src: "",
          title: "游戏舆情分析及报告",
        },
        {
          src: "",
          title: "工厂安全行为检测",
        },
        {
          src: "",
          title: "滑雪者图像检索",
        },
        {
          src: "",
          title: "政企舆情分析",
        },
      ],
      videoBaseUrl: [
        "/tech/cv/target.mp4",
        "/tech/cv/imageSegmentation.mp4",
        "/tech/cv/keyPoint.mp4",
        "/tech/cv/generative.mp4",
        "/tech/nlp/model.mp4",
        "/tech/nlp/info.mp4",
        "/tech/nlp/text.mp4",
        "/tech/nlp/translate.mp4",
      ],
      videoUrl: [],
    };
  },
  components: {
    navigatorBar,
    bottom,
    swiper,
  },
  mounted() {
    this.getBackImg();
    this.getVideoUrl();
    this.getInstanceImgurl();
  },
  methods: {
    getVideoUrl() {
      for (let i = 0; i < this.videoBaseUrl.length; i++) {
        getImgUrl(this.videoBaseUrl[i]).then((res) => {
          if (res.data.code == 200) {
            this.$set(this.videoUrl, i, res.data.data); // 动态设置响应式图片URL
          }
        });
      }
    },
    select(index) {
      this.flag = index;
    },
    getDetail(index) {
      // this.$nextTick(()=>{
      console.log(index);
      this.$router.push({
        path: "/userInstance",
        query: {
          index: index,
        },
      });
    },
    async getBackImg() {
      await getImgUrl("/cooPartnerHomeBackground.png").then((res) => {
        if (res.data.code == 200) {
          this.imgBack = res.data.data;
        }
      });
    },
    getInstanceImgurl() {
      for (let i = 0; i < this.instanceBaseUrlList.length; i++) {
        getImgUrl(this.instanceBaseUrlList[i]).then((res) => {
          if (res.data.code == 200) {
            this.$set(this.instanceList[i], "src", res.data.data); // 动态设置响应式图片URL
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.solution_main_wrapper {
  width: 100vw;
  /* height: 113vw; */
}

.solutionBackground_wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.solutionBackgroundImg {
  width: 100%;
  height: 100%;
}

.techTitle_wrapper {
  margin-top: 7.554vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.techZHTitle {
  font-size: 2.5rem;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #ffffff;
  font-style: italic;
}

.techENTitle {
  font-size: 1.2rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  margin-top: 0.675vw;
  color: #000000;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.solutionMainContext_wrapper {
  width: 90%;
  /* height: 100%; */
  margin: auto;
  /*border: white solid;*/
}

.breadcrumb {
  display: flex;
  flex-wrap: nowrap;
  color: #ffffff;
  font-size: 1.2rem;
  width: 100%;
  height: 1.5%;
  margin-top: 1.96875vw;
}

.bread-divider {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 84%;
  background-color: #506185;
  height: 1px;
  margin-top: -0.9%;
  margin-left: 2%;
}

.pydance_technology_wrapper {
  width: 100%;
  /* background-color: #10e4e9; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pydance_technology_select_wrapper {
  width: 60%;
}

.pydance_technology_select {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.72rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 20px;
}

.active {
  color: #22cbee;
}

.technical_details_introduction_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.technical_details_introduction {
  width: 80%;
}

.technical_detials {
  width: 100%;
  display: flex;
  margin-bottom: 100px;
}

.technical_video {
  width: 65%;
}

.technical_detials_discrip_wrapper {
  margin-left: 30px;
}

.technical_detials_discrip {
  margin-top: 150px;
  color: #ffffff;
  font-weight: 600;
}

.technical_detials_titleZH {
  font-size: 1.9rem;
  color: #ffffff;
  font-weight: 600;
  font-style: italic;
}

.technical_detials_titleEN {
  color: #1bd4ec;
  font-weight: 600;
  font-style: italic;
  font-size: 1rem;
}

.userInstance_wrapper {
  width: 90%;
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.userInstance {
  width: 17%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.5rem;
}

.userInstanceImg {
  width: 100%;
  margin-bottom: 10px;
}
</style>
